/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState, useContext, useReducer } from 'react';
import BrandingForm from '../../components/config/branding/branding-form';
import { HubDetailsContext } from '../../../shared/context/hub-details';
import HubAdminService from '../../services/hub-admin-service';

const Branding = () => {

  const hub = useContext(HubDetailsContext);
  const hubAdminService = new HubAdminService();

  const [brandingConfig, setBrandingConfig] = useState({
    color: "",
    secondaryColor: "",
    logoUrl: ""
  });
  const [primaryPickerActive, setPrimaryPickerActive] = useState(false);
  const [secondaryPickerActive, setSecondaryPickerActive] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    // Set branding.
    const branding = brandingConfig;
    branding.color = hub.hubDetails.hub_styles.primary_color;
    branding.secondaryColor = hub.hubDetails.hub_styles.secondary_color;
    branding.logoUrl = hub.hubDetails.hub_styles.logo_url;
    branding.bannerImageUrl = hub.hubDetails.hub_styles.banner_image_url;
    branding.titleCopy = hub.hubDetails.hub_styles.title_copy;
    branding.bodyCopy = hub.hubDetails.hub_styles.body_copy;
    branding.desktopButtonColumnCount = hub.hubDetails.hub_styles.desktop_button_column_count;
    branding.hideToolsMenu = hub.hubDetails.hub_styles.hide_tools_menu;
    branding.enableUserApproval = hub.hubDetails.hub_styles.enable_user_approval;
    setBrandingConfig(branding);
    forceUpdate();
  }, [hub]);

  // Reset to default colours
  const resetDefaultColors = () => {
    const branding = brandingConfig;
    branding.color = "#70942c";
    branding.secondaryColor = "#213201";
    setBrandingConfig({ ...branding });
  };

  const handleBlur = (e) => {
    if (!e.target.classList.contains('colourPickerTextField')) {
      setPrimaryPickerActive(false);
      setSecondaryPickerActive(false);
    }
  };

  function updateStyles() {
    const updatedBranding = {
      primary_color: brandingConfig.color,
      secondary_color: brandingConfig.secondaryColor,
      banner_image_url: brandingConfig.bannerImageUrl,
      title_copy: brandingConfig.titleCopy,
      body_copy: brandingConfig.bodyCopy,
      desktop_button_column_count: brandingConfig.desktopButtonColumnCount,
      hide_tools_menu: brandingConfig.hideToolsMenu,
      enable_user_approval: brandingConfig.enableUserApproval
    };
    hubAdminService.updateHubBranding(updatedBranding)
      .then(() => {
        hub.setChange(hub.change + 1);
        setIsEditMode(false);
      });
  }

  // Set the required branding config data.
  const setBrandingConfigData = (formControl, value) => {
    const branding = brandingConfig;
    branding[formControl] = value;

    setBrandingConfig({ ...branding });
  };

  const onLogoUpdate = () => {
    hub.setChange(hub.change + 1);
  };

  return (
    <Box sx={{ p: 2 }} onClick={(e) => handleBlur(e)}>
      <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
        <Typography variant="h5" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
          Organisation Settings
        </Typography>
      </Stack>
      <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
      <BrandingForm
        brandingConfig={brandingConfig}
        setBrandingConfigData={setBrandingConfigData}
        resetDefaultColors={resetDefaultColors}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        updateStyles={updateStyles}
        primaryPickerActive={primaryPickerActive}
        setPrimaryPickerActive={setPrimaryPickerActive}
        secondaryPickerActive={secondaryPickerActive}
        setSecondaryPickerActive={setSecondaryPickerActive}
        onLogoUpdate={onLogoUpdate}
      />
    </Box>
  );
};

export default Branding; 