import { Box, Button, Container, Grid, Paper, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserDetailsContext } from '../../../shared/context/user-details';
import { ImageFileUpload } from '../../../shared/components/image-file-upload';
import { Edit, SaveOutlined } from '@mui/icons-material';
import UserService from '../../../shared/services/user-service';
import { UserInformationProviderContext } from '../../../shared/context/user-information-provider';

export const OrganisationSettingsPage = () => {

  const userDetailsContext = useContext(UserDetailsContext);
  const userService = new UserService();
  const userInformationContext = useContext(UserInformationProviderContext);

  const [user, setUser] = useState();
  const [fileData, setFileData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const [updatedDetails, setUpdatedDetails] = useState({
    logo_url: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    postcode: '',
    phone: '',
    organisation: ''
  });

  useEffect(() => {
    if (userDetailsContext.userDetails) {
      setUser(userDetailsContext.userDetails);
      console.log(userDetailsContext.userDetails);
    }
  }, [userDetailsContext.userDetails]);

  useEffect(() => {
    if (user) {
      console.log(user);
      setUpdatedDetails({
        logo_url: user.logo_url,
        address_line_1: user.address_line_1,
        address_line_2: user.address_line_2,
        city: user.city,
        postcode: user.postcode,
        phone: user.phone,
        organisation: user.organisation,
        organisation_contact_email: user.organisation_contact_email
      });
    }
  }, [user]);

  const saveData = () => {
    const form = new FormData();
    form.append("logo", fileData[0]);

    form.append('organisation', JSON.stringify({ ...updatedDetails }));

    userService.updateOrganisationDetails(form)
      .then(response => {
        setFileData([]);
        userInformationContext.openSnackbar({ open: true, message: 'Organisation details updated.', backgroundColor: 'green' });

        userDetailsContext.forceUpdateUserDetails();
        setIsEditing(false);
      });
  };

  const toggleEdit = () => {
    if (isEditing) {
      saveData();
    } else {
      setIsEditing(true);

    }
  };

  const setFormValue = (event) => {
    setUpdatedDetails({ ...updatedDetails, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Toolbar />
      {user && <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Paper
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            minHeight: '100%',
            borderRadius: '10px'
          }}
          variant='outlined'
        >

          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h5" color="text.primary" gutterBottom sx={{ mb: 1 }}>
              Organisation settings
            </Typography>

            <Button
              variant={'contained'}
              disableElevation
              className="removeUppercase"
              startIcon={isEditing ? <SaveOutlined /> : <Edit />}
              onClick={() => toggleEdit()}
              sx={{ minWidth: '100px', height: '40px' }}
            >
              {isEditing ? 'Save' : 'Edit'}
            </Button>
          </Stack>

          <Typography variant="subtitle2" color="text.primary" gutterBottom sx={{ mb: 1, mt: 2 }}>
            Organisation Name:
          </Typography>

          <TextField
            id="color-input"
            variant="outlined"
            size="small"
            name='organisation'
            onChange={setFormValue}
            value={updatedDetails.organisation}
            disabled={!isEditing}
            sx={{ mb: 2 }}
          />

          <Typography variant="subtitle2" color="text.primary" gutterBottom sx={{ mb: 1, mt: 2 }}>
            Organisation Contact Email:
          </Typography>

          <TextField
            id="color-input"
            variant="outlined"
            size="small"
            name='organisation_contact_email'
            onChange={setFormValue}
            value={updatedDetails.organisation_contact_email}
            disabled={!isEditing}
            sx={{ mb: 2 }}
          />

          <Typography variant="subtitle2" color="text.primary" gutterBottom sx={{ mb: 1, mt: 2 }}>
            Organisation Logo:
          </Typography>

          {!isEditing && updatedDetails.logo_url && <Box sx={{ maxWidth: '240px' }}>
            <img
              src={user.logo_url}
              key={user.logo_url}
              alt="logo"
              maxWidth='100%'
              // maxHeight="200px"
              style={{ verticalAlign: 'middle', paddingLeft: '45px', paddingBottom: '15px' }}
            />

          </Box>}


          {!isEditing && !updatedDetails.logo_url && <Typography variant="p" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
            No logo added
          </Typography>}


          {isEditing && <Grid item xs={12} md={9} lg={9}>
            <ImageFileUpload fileData={fileData} setFileData={setFileData} imageCountLimit={1}></ImageFileUpload>
          </Grid>}

          <Typography variant="subtitle2" color="text.primary" gutterBottom sx={{ mb: 1, mt: 2 }}>
            Organisation Address:
          </Typography>

          <TextField
            id="color-input"
            variant="outlined"
            label='Address line 1'
            size="small"
            name='address_line_1'
            onChange={setFormValue}
            value={updatedDetails.address_line_1}
            disabled={!isEditing}
            sx={{ mb: 2 }}
          />

          <TextField
            id="color-input"
            variant="outlined"
            label='Address line 2'
            size="small"
            name='address_line_2'
            onChange={setFormValue}
            value={updatedDetails.address_line_2}
            disabled={!isEditing}
            sx={{ mb: 2 }}
          />

          <TextField
            id="color-input"
            variant="outlined"
            label='City'
            size="small"
            name='city'
            onChange={setFormValue}
            value={updatedDetails.city}
            disabled={!isEditing}
            sx={{ mb: 2 }}
          />

          <TextField
            id="color-input"
            variant="outlined"
            label='Postcode'
            size="small"
            name='postcode'
            onChange={setFormValue}
            value={updatedDetails.postcode}
            disabled={!isEditing}
            sx={{ mb: 2 }}
          />

          <Typography variant="subtitle2" color="text.primary" gutterBottom sx={{ mb: 1, mt: 2 }}>
            Organisation Phone Number:
          </Typography>

          <TextField
            id="color-input"
            variant="outlined"
            label='Phone'
            size="small"
            name='phone'
            onChange={setFormValue}
            value={updatedDetails.phone}
            disabled={!isEditing}
            sx={{ mb: 2 }}
          />

        </Paper>
      </Container>}
    </>
  );
};