import httpClient from '../../shared/http/http-client';

export default class ProjectService {
  http;

  constructor() {
    this.http = httpClient;
  }

  async getProject(projectId) {
    return this.http.get(`seller/project/${projectId}`)
      .then(response => {
        return response.data;
      });
  }

  async getAllProjects() {
    return this.http.get(`seller/project`)
      .then(response => {
        return response.data;
      });
  }

  async updateOneProject(id, projectDetails) {
    return this.http.put(`seller/project/${id}`, projectDetails)
      .then(response => {
        return response.data;
      });
  }

  async deleteOneProject(id) {
    return this.http.delete(`seller/project/${id}`)
      .then(response => {
        return response.data;
      });
  }

  async submitProjectApproval(id) {
    return this.http.post(`seller/project/${id}/submit-for-approval`, {})
      .then(response => {
        return response.data;
      });
  }

  async setProjectToDraftStatus(id) {
    return this.http.post(`seller/project/${id}/set-to-draft`, {})
      .then(response => {
        return response.data;
      });
  }

  async uploadNewBngProjectImage(projectId, form) {
    return this.http.post(`seller/project/${projectId}/add-project-image`, form)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async deleteProjectImage(projectId, imageKey) {
    return this.http.post(`seller/project/${projectId}/delete-image`, { image_key: imageKey })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async pauseProject(projectId) {
    return this.http.post(`seller/project/${projectId}/pause-project`, {})
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async resumeProject(projectId) {
    return this.http.post(`seller/project/${projectId}/resume-project`, {})
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async closeProjects(projectId) {
    return this.http.post(`seller/project/${projectId}/close-project`, {})
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async recordSale(projectId, saleInfo) {
    return this.http.post(`seller/project/${projectId}/record-sale`, saleInfo)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async addNewHabitat(projectId, habitat) {
    return this.http.post(`seller/project/${projectId}/add-habitat`, habitat)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async deleteHabitat(habitatId, habitatType) {
    return this.http.post(`seller/habitat/${habitatId}/delete`, { habitat_type: habitatType })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async getUnitProjectTypes() {
    return this.http.get(`seller/project/project-types`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async addNewIssuance(projectId, unitProjectId, issuance) {
    return this.http.post(`seller/project/${projectId}/${unitProjectId}/add-issuance`, issuance)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async editIssuance(projectId, unitProjectId, issuance) {
    return this.http.patch(`seller/project/${projectId}/${unitProjectId}/edit-issuance`, issuance)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async deleteIssuance(projectId, unitProjectId, issuance) {
    return this.http.delete(`seller/project/${projectId}/${unitProjectId}/delete-issuance`, issuance)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }
}