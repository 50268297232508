import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { NumericFormat } from 'react-number-format';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import TollIcon from '@mui/icons-material/Toll';
import httpClient from '../../shared/http/http-client';
import LockIcon from '@mui/icons-material/Lock';
import Skeleton from '@mui/material/Skeleton';
import { HubDetailsContext } from '../../shared/context/hub-details';
import { UserDetailsContext } from '../../shared/context/user-details';
import { SignUpDialog } from '../../shared/components/dialogs/sign-up';
import { ImageFullSizeDisplay } from '../../shared/components/image-full-size-display';
import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItem, ListItemIcon, ListItemText, Tooltip, useMediaQuery } from '@mui/material';
import IconPicker from '../../shared/components/icon-picker';
import AnswerFormDisplay from '../../shared/components/answer-form-display';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProjectDetailSummary from '../../shared/components/project-details-summary';
import { DownloadResourceButton } from '../../shared/components/project-resources/download-resource-button';
import { MetaTagProvider } from '../../shared/components/mata-tag-provider';
import { BuyerEnquiryButton } from '../../shared/components/enquiry-button';
import gaiaTheme from '../../theme';
import { ContentCopy } from '@mui/icons-material';
import { SocialMediaShareComponent } from '../../shared/components/presentation/social-media-share';

const ProjectDetails = (props) => {

  const location = useLocation();
  const state = location.state;
  const project = state ? state.project : null;
  let navigate = useNavigate();
  const isTablet = useMediaQuery(gaiaTheme.breakpoints.down('md'));


  const [freeTier, setFreeTier] = useState(false);
  const [projectDetails, setProjectDetails] = useState(project);
  const [projectImages, setProjectImages] = useState([]);
  const [enquirySent, setEnquirySent] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [projectSaved, setProjectSaved] = useState(null);
  const [canSendEnquiry, setCanSendEnquiry] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [isHub, setIsHub] = useState(false);
  const [isMainHub, setIsMainHub] = useState(true);
  const [groupAtProjectlevel, setGroupAtProjectLevel] = useState(false);
  const [signUpFormActive, setSignUpFormActive] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const hub = useContext(HubDetailsContext);
  const user = useContext(UserDetailsContext);

  useEffect(() => {
    window.analytics.page();
    if (user.userDetails) {
      if (user.userDetails.name) {
        setAuthenticated(true);
      }
      if (user.userDetails.enquiries_remaining === 0) {
        setCanSendEnquiry(false);
      }
      // if (user.userDetails.accessLevel === 0 && hub.hubDetails.main_hub) {
      //   setFreeTier(true);
      // }
      setIsAdmin(user.userDetails.isAdmin);
    }

    setIsMainHub(hub?.hubDetails?.main_hub);
    setIsHub(true);

    getProject();



  }, [hub, user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const copyLinkClick = () => {
    navigator.clipboard.writeText(window.location.href);
    //setShareAlertOpen(true);
  };

  const toggleSignUpFormActive = () => {

    if (signUpFormActive) {
      getProject();
    }

    setSignUpFormActive(!signUpFormActive);
  };

  const handleSaveProject = () => {
    httpClient.post('user/toggle-favourite', { project_id: projectDetails.p_id })
      .then(() => {
        setProjectSaved(!projectSaved);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const enquirySuccess = () => {
    setEnquirySent(true);
    getProject();
    if (user.userDetails) {
      if (user.userDetails.enquiries_remaining === 0) {
        setCanSendEnquiry(false);
      }
    }
  };

  const getProject = async () => {
    const path = window.location.pathname;
    const projectId = path.substring(path.lastIndexOf('/') + 1);
    // const route = !hub.hubDetails.main_hub ? 'hub' : 'buyer';
    const route = 'hub';
    httpClient.get(`${route}/project/${projectId}`)
      .then(response => {
        const dateAddedISO = new Date(response.data.created_at.replace(' ', 'T'));
        const dateAdded = dateAddedISO.toLocaleDateString('en-GB');
        const project = { ...response.data, dateAdded: dateAdded };
        setProjectImages(response.data.project_images);
        setProjectDetails(project);
        setProjectSaved(response.data.isSaved);
        setGroupAtProjectLevel(response.data.group_at_project_level);
        setIsLoading(false);
      })
      .catch(err => {
        navigate('/hub/marketplace');
      });
  };

  return (
    <>
      <Toolbar />
      {projectDetails && <MetaTagProvider
        metaTitle={`Biodiversity net gain units | ${projectDetails.p_region} - ${projectDetails.p_summary}`}
        metaDescription={`Biodiversity net gain units for sale in ${projectDetails.p_region}. ${projectDetails.description}`}>
      </MetaTagProvider>}

      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid container alignItems="center" sx={{ mb: 2 }}>
          <Grid item xs={6} md={6} lg={6} alignItems="center" key={uuidv4()}>
            <Link
              component="button"
              variant="body2"
              onClick={() => navigate(-1)}
              underline='hover'
              sx={{ ml: '-7px' }}
            >
              <Grid container direction="row" alignItems="center">
                <KeyboardArrowLeft />
                <Typography variant="button" display="block" className='removeUppercase'>
                  Back to search results
                </Typography>
              </Grid>
            </Link>
          </Grid>
          <Grid item xs={6} md={6} lg={6} key={uuidv4()}>
            <Stack direction="row" spacing={0} justifyContent="flex-end" alignItems="center">
              {authenticated && <Tooltip title="Save as favourite">
                <IconButton
                  aria-label="favourite"
                  onClick={() => handleSaveProject()}
                >
                  {(!isLoading && !projectSaved) ? (<FavoriteBorderOutlinedIcon />) : (<FavoriteIcon />)}
                </IconButton>
              </Tooltip>}
              <Tooltip title="Copy link to clipboard">
                <IconButton aria-label="share" onClick={() => copyLinkClick()}>
                  <ContentCopy />
                </IconButton>
              </Tooltip>

              <SocialMediaShareComponent />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={4} flexDirection={isTablet ? 'column-reverse' : 'row'}>
          <Grid item xs={12} sm={12} md={8} lg={8} key={uuidv4()}>
            <Paper
              elevation={0}
              sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto'
              }}
            >

              {projectImages && <ImageFullSizeDisplay
                images={projectImages}
                canEdit={false}
                indicators={true}
                primaryImageKey={projectDetails?.p_primary_image_name}
              >
              </ImageFullSizeDisplay>}

              <ProjectDetailSummary projectDetails={projectDetails}
                groupAtProjectlevel={groupAtProjectlevel}
                freeTier={freeTier}
                enquirySent={enquirySent}
                isMainHub={isMainHub}
                isAdmin={isAdmin}
              >
              </ProjectDetailSummary>

              {projectDetails?.answer_forms?.length > 0 && <Divider sx={{ mt: 6, mb: 6 }} />}

              {projectDetails?.answer_forms?.length > 0 && <>
                {projectDetails?.answer_forms?.reduce((total, form) => total + form.answers?.length, 0) > 0 && <Typography variant="h6" color="text.primary" sx={{ mt: 2, mb: 2, whiteSpace: 'pre-wrap' }}>
                  Information provided:
                </Typography>}

                {projectDetails?.answer_forms?.filter(form => form.answers.length > 0).map((form, index) => {
                  return <Accordion
                    key={index}
                    expanded={expanded === `panel-${index}`}
                    onChange={handleChange(`panel-${index}`)}>

                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{ alignItems: 'center', justifyContent: 'center' }}

                    >
                      <List sx={{ p: 0 }}>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemIcon>
                            <IconPicker icon="form" color='primary' />
                          </ListItemIcon>
                          <ListItemText primary={form.question_form?.form_name} />
                        </ListItem>
                      </List>
                    </AccordionSummary>
                    <AccordionDetails>
                      <AnswerFormDisplay key={index} suppliedAnswers={form.answers} />
                    </AccordionDetails>
                  </Accordion>;
                })}
              </>
              }
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} key={uuidv4()}>
            {!isLoading && (
              <Paper
                elevation={5}
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  position: 'sticky',
                  top: '90px',
                  borderRadius: '10px'
                }}
              >
                <Grid container flexDirection={isTablet ? 'column-reverse' : 'row'}>
                  <Grid item xs={12} md={6} lg={6} key={uuidv4()}>
                    <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                      {!freeTier ? (isMainHub ? projectDetails.p_local_planning_authority : projectDetails.p_summary) : (
                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                          <Skeleton animation={false} width='100%' height='40px' />
                          <LockIcon sx={{ color: '#00000099' }} />
                        </Stack>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} key={uuidv4()}>
                    <Chip
                      label={projectDetails?.shallow_status?.status_name || 'Available'}
                      color="primary"
                      variant="outlined"
                      //icon={<FiberManualRecordIcon />}
                      sx={{
                        justifyContent: "flex-end", ml: "auto", float: "right", color: projectDetails?.shallow_status?.color || "primary",
                        borderColor: projectDetails?.shallow_status?.color || "primary",
                        mb: isTablet ? 2 : 0
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }} key={uuidv4()}>
                    <Typography variant="p" color="text.secondary">
                      {isMainHub ? projectDetails?.p_national_character_area : projectDetails?.custom_region_tag}
                    </Typography>
                  </Grid>

                  {projectDetails.enhanced_branding && <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                    {projectDetails.enhanced_branding.logo_url && <>
                      <Stack justifyContent={'center'} >
                        <img
                          src={projectDetails.enhanced_branding.logo_url}
                          alt="logo"
                          style={{ maxHeight: '200px', maxWidth: "400px", objectFit: 'contain', paddingLeft: '20%', paddingRight: '20%' }}
                        />
                      </Stack>

                      <Divider sx={{ mb: 2, mt: 1 }}></Divider>
                      <Stack justifyContent={'center'} >
                        {projectDetails.enhanced_branding.organisation && <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600, textAlign: 'center', mb: 1 }}>
                          {projectDetails.enhanced_branding.organisation}
                        </Typography>}
                      </Stack>
                      {projectDetails.enhanced_branding.address_line_1 && projectDetails.enhanced_branding.postcode && <Stack justifyContent={'center'} >
                        <Typography variant="p" color="text.secondary" sx={{ textAlign: 'center', mb: 1 }}>
                          {projectDetails.enhanced_branding.address_line_1}
                          {projectDetails.enhanced_branding.address_line_2 ? ', ' : ''}{projectDetails.enhanced_branding.address_line_2}
                          {projectDetails.enhanced_branding.city ? ', ' : ''}{projectDetails.enhanced_branding.city}
                          {projectDetails.enhanced_branding.postcode ? ', ' : ''}{projectDetails.enhanced_branding.postcode}
                        </Typography>

                        <Typography variant="p" color="text.secondary" sx={{ textAlign: 'center', mb: 1 }}>
                          {projectDetails.enhanced_branding.organisation_contact_email}
                          {projectDetails.enhanced_branding.phone ? ' | ' : ''}{projectDetails.enhanced_branding.phone}
                        </Typography>
                      </Stack>}
                      <Divider sx={{ mb: 2, mt: 1 }}></Divider>
                    </>}

                  </Grid>}
                  <Grid item xs={12} md={12} lg={5} sx={{ mt: 1 }} key={uuidv4()}>
                    <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                      <TollIcon color="primary" />
                      {projectDetails.totalBiodiversityUnitsRemaining !== 0 && <Typography variant="p" color="text.secondary">
                        <span style={{ lineHeight: '24px' }}>
                          <b>Units available: </b><br></br><NumericFormat value={projectDetails.totalBiodiversityUnitsRemaining} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                        </span>
                      </Typography>}

                      {projectDetails.totalBiodiversityUnitsRemaining === 0 && <Typography variant="p" color="text.secondary">
                        <span style={{ lineHeight: '24px' }}>
                          <b>Units available: </b> TBC
                        </span>
                      </Typography>}

                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12} lg={7} sx={{ mt: 1 }} key={uuidv4()}>

                    <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                      <SellOutlinedIcon color="primary" />
                      <Typography variant="p" color="text.secondary">
                        <span style={{ lineHeight: '24px' }}>
                          <b>Unit price: </b><br></br>
                          <Typography variant="p" color="text.secondary">
                            {(!projectDetails.hidePrice && projectDetails.totalPrice !== "0") && <Stack direction={'row'} alignItems={'center'}>
                              <NumericFormat value={Math.round(projectDetails.lowestPriceUnit)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                              {projectDetails.lowestPriceUnit !== projectDetails.highestPriceUnit && <>&nbsp;-&nbsp;</>}
                              {projectDetails.lowestPriceUnit !== projectDetails.highestPriceUnit && <NumericFormat value={Math.round(projectDetails.highestPriceUnit)} displayType={'text'} thousandSeparator={true} prefix={'£'} />}
                            </Stack>}

                            {(projectDetails.hidePrice || projectDetails.totalPrice === "0") && <Stack direction={'row'} alignItems={'center'}>
                              POA
                            </Stack>}

                          </Typography>
                        </span>
                      </Typography>

                    </Stack>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 2, mb: 2 }} />
                {authenticated && <Stack
                  spacing={2}
                >
                  {projectDetails && <BuyerEnquiryButton
                    projectId={projectDetails.p_id}
                    enquiryId={projectDetails.enquiryId}
                    projectType={'BNG_PROJECT'}
                    freeTier={freeTier}
                    onSuccess={enquirySuccess}
                    userRole={'buyer'}
                  />}

                  {projectDetails.resources?.length > 0 && <DownloadResourceButton buttonCopy={'Download brochure'} route={projectDetails.resources[0].location} />}

                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    disableElevation
                    className='removeUppercase'
                    startIcon={!projectSaved ? (<FavoriteBorderOutlinedIcon />) : (<FavoriteIcon />)}
                    onClick={() => handleSaveProject()}
                    disabled={freeTier}
                  >
                    {!projectSaved ? ('Save as favourite') : ('Favourite')}
                  </Button>
                </Stack>}

                {!authenticated && <Stack
                  spacing={2}
                >
                  {!projectDetails?.enquiryId && <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disableElevation
                    className='removeUppercase'
                    startIcon={<MailOutlineIcon />}
                    onClick={() => toggleSignUpFormActive()}
                    disabled={authenticated}
                  >
                    Contact Seller
                  </Button>}


                </Stack>}

                {signUpFormActive && <SignUpDialog open={signUpFormActive} closeDialog={toggleSignUpFormActive} role='BUYER' />}
              </Paper>
            )}
            {(!isLoading && freeTier && !isHub) && (
              <Paper
                elevation={5}
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  position: 'sticky',
                  top: '425px',
                  borderRadius: '10px',
                  mt: 2,
                  backgroundColor: '#70942c'
                }}
              >
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h6" sx={{ fontWeight: 600, color: '#fff', textAlign: 'center' }}>
                      Sign up to a paid plan to unlock the listing and enquire about the units.
                    </Typography>
                    <Button
                      variant="outlined"
                      disableElevation
                      size="large"
                      onClick={() => navigate(`/payment`)}
                      className="removeUppercase"
                      sx={{
                        borderRadius: '30px', color: '#fff', borderColor: '#fff', width: '100%', mt: 2, "&:hover": {
                          backgroundColor: "#fff", color: "#70942c"
                        }
                      }}
                    >
                      <b>Upgrade now</b>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Container >
    </>
  );
};

export default ProjectDetails;