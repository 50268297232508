import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Toolbar, Typography, Box, Container, Grid, TextField, InputAdornment, Paper, Card, CardActionArea, Stack, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ImagePicker from '../../shared/components/image-picker';
import { HubDetailsContext } from '../../shared/context/hub-details';
import HubButtonService from '../../shared/services/hub-buttons-service';
import EastIcon from '@mui/icons-material/East';

const HubPage = () => {

  const [buttons, setButtons] = useState();
  const [desktopColumnCount, setDesktopColumnCount] = useState();
  const [buttonTitleTypography, setButtonTitleTypography] = useState('h6');
  const [callToAction, setCallToAction] = useState('');
  const [mainHub, setIsMainHub] = useState(true);

  const hub = useContext(HubDetailsContext);
  const hubButtonService = HubButtonService.getInstance();
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {

    let buttonUserType = undefined;
    // Check if this is a page for a specific role, and if so set this for use in the http call.
    if (location.pathname.indexOf('seller') !== -1) {
      buttonUserType = 'SELLER'
      setCallToAction('View more')
    } else if (location.pathname.indexOf('buyer') !== -1) {
      buttonUserType = 'BUYER';
      setCallToAction('Browse projects')
    } else {
      setCallToAction('View more')
    }

    if (hub.hubDetails) {
      setDesktopColumnCount(12 / hub.hubDetails?.hub_styles.desktop_button_column_count);
      setButtonTitleTypography(hub.hubDetails?.hub_styles.desktop_button_column_count < 3 ? 'h5' : 'h6')
      setIsMainHub(hub.hubDetails.main_hub);
    };

    hubButtonService.getAvailableButtons(false, buttonUserType)
      .then(response => {
        setButtons(response)
      })
  }, [hub.hubDetails])

  const handleClick = (button) => {

    if (button.route_is_internal) {
      navigate(button.url);
    } else {
      window.open(button.url, '_self')
    }
  }

  return (<>
    <>
      <Toolbar />
      <Box
        sx={{
          minHeight: '45vh',
          pb: 11,
          backgroundImage: `url(${hub.hubDetails.hub_styles?.banner_image_url || '/hub_banner_1.jpg'})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 2 }}>
          <Grid container spacing={2} >
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                variant="h3"
                component="h1"
                sx={{ mb: 2, textAlign: 'left', fontWeight: 600, color: "common.white" }}
              >
                {hub.hubDetails.hub_styles.title_copy}
              </Typography>
              <Typography
                variant="h6"
                sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
              >
                {hub.hubDetails.hub_styles.body_copy}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{
        backgroundColor: '#f6f6f6', minHeight: '40vh'
      }}>
        <Container maxWidth="lg" sx={{ pt: 8, pb: 4 }}>
          <Grid container spacing={4} sx={{ mt: -23 }}>
            {buttons && desktopColumnCount && buttons.map((button, index) => {
              return <Grid key={index} item xs={12} md={desktopColumnCount} lg={desktopColumnCount}>
                <Card variant='outlined' sx={{ borderRadius: '10px', minHeight: '340px', height: '100%' }}>
                  <CardActionArea
                    onClick={() => handleClick(button)}
                    sx={{ p: 6, alignItems: 'center', justifyContent: 'space-between', height: '100%', display: 'flex', flexDirection: 'column' }}
                  >
                    <Box sx={{ width: '80px', ml: 'auto', mr: 'auto', mb: 2, minHeight: '90px' }}>
                      <ImagePicker image={button.icon_code} />
                    </Box>
                    <Stack direction={'column'} justifyContent={'space-between'} height={'100%'}>
                      <Box>
                        <Typography
                          variant={buttonTitleTypography}
                          sx={{ textAlign: 'center' }}
                        >
                          <b>{button.title_copy}</b>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: 'center' }}
                          color="text.secondary"
                        >
                          {button.body_copy}
                        </Typography>
                      </Box>

                      <Button endIcon={<EastIcon />} variant='contained' disableElevation className='removeUppercase' sx={{ mt: 4, ml: 'auto', mr: 'auto', borderRadius: '30px' }}>
                        {callToAction}
                      </Button>
                    </Stack>
                  </CardActionArea>
                </Card>
              </Grid>
            })}
          </Grid>
        </Container>
      </Box>
      {hub.hubDetails.base_url === 'nationaltrust.gaiacompany.io' && <>
        <Box>
          <Container maxWidth="lg" sx={{ pt: 8, pb: 8 }}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h4" sx={{ pb: 2 }}><b>Introduction -</b> Feckenham to Arden Forest</Typography>
                <Typography variant="body1" sx={{ pb: 2 }}>Most of this area would have been part of two ancient forests, the Forest of
                  Arden and the Forest of Feckenham. Very little of the original forest remains and the area under performs in providing benefits for people and
                  nature.
                </Typography>
                <Typography variant="body1" sx={{ pb: 2 }}>
                  The National Forest status will catalyse the restoration of this culturally
                  significant place, recreating a forest landscape that people can benefit from
                  and access from their own doorsteps.
                </Typography>
                <Typography variant="body1" sx={{ pb: 2 }}>
                  The ambition is to enhance and connect existing habitat through new
                  networks of trees and woodland, linking urban areas like Bromsgrove,
                  Dudley and Birmingham to the wider countryside to increase biodiversity
                  and recreation.
                </Typography>
                <Typography variant="body1" sx={{ pb: 2 }}>
                  Long term sustainability is key, with the development of innovative financial
                  and stewardship mechanisms supported by a strong partnership of
                  stakeholders.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box
                  sx={{
                    backgroundImage: 'url(/nt/nt-1.jpg)',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    height: '100%',
                    borderRadius: '30px',
                    ml: 8,
                    mr: 8
                  }}
                >
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ backgroundColor: '#f6f6f6' }}>
          <Container maxWidth="lg" sx={{ pt: 8, pb: 8 }}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={6} lg={6}>
                <Box
                  sx={{
                    backgroundImage: 'url(/nt/nt-3.jpg)',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    height: '100%',
                    borderRadius: '30px',
                    ml: 8,
                    mr: 8
                  }}
                >
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h4" sx={{ pb: 2 }}>What is the Urban Greening Code?</Typography>
                <Typography variant="body1" sx={{ pb: 2 }}>Urban green infrastructure projects are small (usually 0.5 ha or less) and
                  often contain interventions such as the addition of street trees, pocket
                  parks, rain gardens and retention ponds.
                </Typography>
                <Typography variant="body1" sx={{ pb: 2 }}>
                  Existing nature market opportunities are typically oriented towards
                  large-scale rural projects. We will be piloting a code based on the
                  quantification of societal benefits that could help to establish an
                  urban-focused nature market and could help channel investment into Urban
                  Green Infrastructure (UGI) projects.
                </Typography>
                <Typography variant="body1" sx={{ pb: 2 }}>
                  Given limited market infrastructure and the small scale of most UGI
                  projects, a token model is currently the most viable option
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
      }
    </>
  </>
  );
};

export default HubPage