import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Switch, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import HubAdminService from '../../../services/hub-admin-service';
import httpClient from '../../../../shared/http/http-client';
import { HubDetailsContext } from '../../../../shared/context/hub-details';
import { CheckBox, ExpandMore } from '@mui/icons-material';
import IconPicker from '../../../../shared/components/icon-picker';

const ConfigMarketplaceModule = () => {

  const hubAdminService = new HubAdminService();

  const location = useLocation();
  const state = location.state;
  const hubDetailsContext = useContext(HubDetailsContext);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditingProjectTypes, setIsEditingProjectTypes] = useState(false);
  const [tile, setTile] = useState(state ? state.tile : null);
  const [moduleBrandingConfig, setModuleBrandingConfig] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [projectTypes, setProjectTypes] = useState();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSaveClick = () => {
    hubAdminService.updateHubModuleStyle(tile.module_style[0]?.id, moduleBrandingConfig)
      .then(() => {
        setIsEditMode(false);
      });
  };

  // Set the required branding config data.
  const setBrandingConfigData = (formControl, value) => {

    const branding = moduleBrandingConfig;
    branding[formControl] = value;
    setModuleBrandingConfig({ ...branding });
  };

  // Toggle the active tile for the hub user.
  const toggleActiveTile = (tileId) => {
    hubAdminService.toggleTileActive(tileId)
      .then((response) => {
        getAvailableTiles();
        hubDetailsContext.setChange(hubDetailsContext.change + 1);

      });
  };

  function getAvailableTiles() {
    httpClient.get('admin/hub/available-tiles')
      .then(response => {
        setTile(response.data.filter((a) => a.tile_code === tile.tile_code)[0]);
      });
  }

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const styleConfig = {
      title_copy: tile.module_style[0]?.title_copy,
      body_copy: tile.module_style[0]?.body_copy,
      banner_image_url: tile.module_style[0]?.banner_image_url
    };
    setModuleBrandingConfig(styleConfig);
  }, [tile]);

  useEffect(() => {
    getProjectTypes();
  }, []);

  const getProjectTypes = () => {
    hubAdminService.getAllProjectTypes()
      .then(response => {
        setProjectTypes(response);
      });
  };

  const toggleProjectTypeActive = (projectType) => {
    if (projectType.active) {
      hubAdminService.disableProjectType(projectType.id)
        .then(() => {
          getProjectTypes();
        });
    } else {
      hubAdminService.enableProjectType(projectType.id, { display_index: projectType.display_index, coming_soon: false, coming_soon_message: 'Coming soon' })
        .then(() => {
          getProjectTypes();
        });
    }
  };

  const toggleComingSoon = (projectType) => {
    hubAdminService.toggleComingSoon(projectType.id, { display_index: projectType.display_index, coming_soon: !projectType.coming_soon, coming_soon_message: 'Coming soon' })
      .then(() => {
        getProjectTypes();
      });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
        <Typography variant="h5" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
          <Link
            underline="hover"
            color="text.primary"
            to="/admin/configuration/modules"
            style={{ color: 'black' }}
          >
            Modules
          </Link> &nbsp;/&nbsp;  Marketplace
        </Typography>
        <Switch
          onClick={() => toggleActiveTile(tile.id)}
          checked={tile.is_activated_by_hub}
          sx={{ mr: 1 }}
        />

      </Stack>
      <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
      {/* <DisplayForm
        tile={tile}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        handleSaveClick={handleSaveClick}
        setBrandingConfigData={setBrandingConfigData}
        moduleBrandingConfig={moduleBrandingConfig}
        setModuleBrandingConfig={setModuleBrandingConfig}
      /> */}

      <Grid item xs={12} md={12} lg={12}>
        <Accordion
          key={1}
          expanded={expanded === `panel-${1}`}
          onChange={handleChange(`panel-${1}`)}
          elevation={0}
          square
          sx={{
            border: `1px solid rgba(0, 0, 0, 0.12)`,
            '&:not(:last-child)': {
              borderBottom: 0,
            },
            '&::before': {
              display: 'none',
            },
            borderRadius: '5px'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemIcon>
                  <IconPicker icon="palette" />
                </ListItemIcon>
                <ListItemText primary="Display" secondary="Configure the appearance of the module." />
              </ListItem>
            </List>
          </AccordionSummary>
          <AccordionDetails>

            <Stack direction={'row'} justifyContent={'flex-end'} sx={{ mb: 2 }}>
              {isEditMode ?
                <Button
                  variant='contained'
                  disableElevation
                  className="removeUppercase"
                  startIcon={<SaveOutlinedIcon />}
                  onClick={() => handleSaveClick()}
                  sx={{ minWidth: '90px' }}
                >
                  Save
                </Button> :
                <Button
                  variant='outlined'
                  disableElevation
                  className="removeUppercase"
                  startIcon={<EditIcon />}
                  onClick={() => setIsEditMode(true)}
                  sx={{ minWidth: '90px' }}
                >
                  Edit
                </Button>
              }
            </Stack>

            <Grid container spacing={4} sx={{ pl: '56px', pr: '56px', pt: '10px', pb: '30px' }}>
              <Grid item xs={12} md={3} lg={4}>
                <Typography variant="subtitle1" color="text.primary">
                  Title:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={8}>
                {isEditMode ?
                  <TextField
                    id="title"
                    variant="outlined"
                    size="small"
                    onChange={e => setBrandingConfigData('title_copy', e.target.value)}
                    value={moduleBrandingConfig?.title_copy}
                    sx={{ width: '100%' }}
                  />
                  :
                  <Typography variant="subtitle1" color="text.secondary">
                    {moduleBrandingConfig?.title_copy}
                  </Typography>
                }
              </Grid>
              <Grid item xs={12} md={3} lg={4}>
                <Typography variant="subtitle1" color="text.primary">
                  Subtitle:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={8}>
                {isEditMode ?
                  <TextField
                    id="subtitle"
                    variant="outlined"
                    size="small"
                    onChange={e => setBrandingConfigData('body_copy', e.target.value)}
                    value={moduleBrandingConfig?.body_copy}
                    sx={{ width: '100%' }}
                  />
                  :
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{
                      wordBreak: 'break-all',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {moduleBrandingConfig?.body_copy}
                  </Typography>
                }
              </Grid>
              <Grid item xs={12} md={3} lg={4}>
                <Typography variant="subtitle1" color="text.primary">
                  Background image URL:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={8}>
                {isEditMode ?
                  <TextField
                    id="background-image-url"
                    variant="outlined"
                    size="small"
                    onChange={e => setBrandingConfigData('banner_image_url', e.target.value)}
                    value={moduleBrandingConfig?.banner_image_url}
                    sx={{ width: '100%' }}
                  />
                  :
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{
                      wordBreak: 'break-all',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {moduleBrandingConfig?.banner_image_url}
                  </Typography>
                }
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>


        <Accordion
          key={2}
          expanded={expanded === `panel-${2}`}
          onChange={handleChange(`panel-${2}`)}
          elevation={0}
          square
          sx={{
            border: `1px solid rgba(0, 0, 0, 0.12)`,
            '&:not(:last-child)': {
              borderBottom: 0,
            },
            '&::before': {
              display: 'none',
            },
            borderRadius: '5px'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            sx={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemIcon>
                  <IconPicker icon="palette" />
                </ListItemIcon>
                <ListItemText primary="Available project types" secondary="Select the type of project that users can start." />
              </ListItem>
            </List>
          </AccordionSummary>
          <AccordionDetails>

            {projectTypes?.map((projectType, index) => {
              return (
                <Stack direction={'row'} alignItems={'center'}>
                  <Typography variant="subtitle1" color="text.primary" sx={{ minWidth: '250px', textAlign: 'right', mr: 2 }}>
                    {projectType.name}
                  </Typography>
                  <Switch
                    onClick={() => toggleProjectTypeActive(projectType)}
                    checked={projectType.active}
                    sx={{ mr: 1 }}
                  />

                  {projectType.active && <FormControl fullWidth>
                    <FormControlLabel
                      label="Mark as 'Coming soon'"
                      control={
                        <Checkbox
                          name='sendEmail'
                          checked={projectType.coming_soon}
                          value={projectType.coming_soon || false}
                          onClick={() => toggleComingSoon(projectType)}
                        />}
                    />
                  </FormControl>}
                </Stack>
              );
            })}
          </AccordionDetails>


        </Accordion>
      </Grid>
    </Box>
  );
};

export default ConfigMarketplaceModule;