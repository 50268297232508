import httpClient from '../../shared/http/http-client';

export default class AdminProductAddOnService {
  http;

  constructor() {
    this.http = httpClient;
  }

  async getAllAddOns() {
    return this.http.get(`admin/product-add-on`)
      .then(response => {
        return response.data;
      });
  }

  async getAddOn(code) {
    return this.http.get(`admin/product-add-on/${code}`)
      .then(response => {
        return response.data;
      });
  }

  async activateNewRecord(record) {
    return this.http.post(`admin/product-add-on/activate`, record)
      .then(response => {
        return response.data;
      });
  }

  async disableRecord(recordId) {
    return this.http.post(`admin/product-add-on/disable`, { id: recordId })
      .then(response => {
        return response.data;
      });
  }
}