import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from '@mui/material/Table';
import Skeleton from '@mui/material/Skeleton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import AdminEnquiriesService from '../services/admin-enquiries-service';
import { Box, Button, Container, Grid, Paper, Stack, TableHead, Toolbar, Typography } from '@mui/material';
import moment from 'moment';
import Title from '../../shared/components/title';
import { Download } from '@mui/icons-material';

export const AdminEnquiries = () => {
  const adminEnquiriesService = new AdminEnquiriesService();

  const [enquiries, setEnquiries] = useState();
  const [projectType, setProjectType] = useState('BNG_PROJECT');

  const getEnquiries = (projectType) => {
    adminEnquiriesService.getEnquiries(projectType)
      .then(response => {
        setEnquiries(response);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = e => {
    e.preventDefault();
    let headers = ['enquiry_id, created_at, project_title, seller, Buyer, lpa'];
    let enquiriesCsv = enquiries.reduce((acc, enquiry) => {
      const { e_id, created_at, project } = enquiry;
      acc.push([e_id, created_at, `"${project?.p_summary}"`, `"${project?.owner?.name}"`, `"${enquiry.made_by_user?.name}"`, `"${project?.p_local_planning_authority}"`].join(','));
      return acc;
    }, []);
    downloadFile({
      data: [...headers, ...enquiriesCsv].join('\n'),
      fileName: 'enquiries.csv',
      fileType: 'text/csv',
    });
  };

  useEffect(() => {
    getEnquiries(projectType);
  }, [projectType]);

  return (<>
    <Toolbar />
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Paper
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          minHeight: 'calc(100vh - 130px)',
          borderRadius: '10px'
        }}
        variant='outlined'
      >
        <Stack direction='row' sx={{ alignItems: 'center', pb: 2 }}>
          <Title style={{ marginBottom: '0px' }}>Enquiries</Title>
          <Box sx={{ flex: 1 }}>
            <Button variant='outlined' startIcon={<Download />} onClick={exportToCsv} disableElevation className='removeUppercase' sx={{ float: 'right' }}>
              Export to CSV
            </Button>
          </Box>
        </Stack>
        {!enquiries && <Table>
          <TableBody>
            {Array.from(new Array(20)).map((enquiry, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>}
        {enquiries?.length === 0 && (
          <Alert severity="info" sx={{ p: 2, m: 8 }}>No enquiries have been received yet.</Alert>
        )}
        {enquiries?.length > 0 && (
          <Box sx={{
            maxWidth: '100%',
            overflow: 'auto'
          }}>
            <Table>
              <TableHead>
                <TableRow sx={{ fontWeight: 600 }}>
                  <TableCell sx={{ fontWeight: 600, pl: 0 }}>Created</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Project type</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Project title</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Seller</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Buyer</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>LPA</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enquiries && enquiries.map((enquiry, index) => {
                  return <TableRow key={index} component={Link} to={`/admin/enquiry/${enquiry.e_id}`} hover={true}>
                    <TableCell sx={{ pl: 0 }}>{moment(enquiry.created_at).format('DD/MM/yyyy')}<br></br> <Typography fontWeight={600} color={'primary'} variant='p'>{moment(enquiry.updated_at).fromNow(true)} ago</Typography></TableCell>
                    <TableCell>{enquiry.project_p_id ? 'BNG Project' : 'Investment Project'}</TableCell>
                    <TableCell>{enquiry.project_p_id ? enquiry.project.p_summary : enquiry.investment_project?.title_text}</TableCell>
                    <TableCell>{enquiry.project_p_id ? enquiry.project?.owner?.name : enquiry.investment_project?.subtitle_text}</TableCell>
                    <TableCell>{enquiry.project_p_id ? enquiry.made_by_user?.name : enquiry.investment_project?.subtitle_text}</TableCell>
                    <TableCell>{enquiry.project_p_id ? enquiry.project?.p_local_planning_authority : enquiry?.investment_project?.custom_region_tag}</TableCell>
                  </TableRow>;
                })}
              </TableBody>
            </Table>
          </Box>
        )}
      </Paper>
    </Container>
  </>
  );
};
