import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { HubDetailsContext } from '../../shared/context/hub-details';
import Autocomplete from '@mui/material/Autocomplete';
import { nationalCharacterAreasAutocomplete } from '../../shared/data/national-character-areas';
import IconPicker from '../../shared/components/icon-picker';
import httpClient from '../../shared/http/http-client';

const NutrientCredits = () => {
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [titleCopy, setTitleCopy] = useState("");
  const [bodyCopy, setBodyCopy] = useState("");
  const [isHub, setIsHub] = useState("");
  const [isLoading, setIsLoading] = useState("");

  const hub = useContext(HubDetailsContext);

  useEffect(() => {
    httpClient.get('data/hub/module-style/nutrient-credits')
      .then(moduleStyle => {
        if (moduleStyle.data[0].banner_image_url) {
          setBannerImageUrl(moduleStyle.data[0]?.banner_image_url)
        } else {
          setBannerImageUrl(hub.hubDetails.hub_styles.banner_image_url)
        }
        setTitleCopy(moduleStyle.data[0]?.title_copy ? moduleStyle.data[0].title_copy : "Nutrient nutrality credits");
        setBodyCopy(moduleStyle.data[0]?.body_copy ? moduleStyle.data[0].body_copy : "Purchase nutrient nutrality credits");
        setIsHub(true);
        setIsLoading(false);
      })
  }, [hub])

  useEffect(() => {
    window.analytics.page();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Toolbar />
      <Box
        sx={{
          //p: isMobileMode ? '14%' : '8%',
          //pb: isMobileMode ? '29%' : '8%',
          height: '55vh',
          mt: 2,
          //backgroundColor: "primary.light"
          // backgroundImage: "url('/hub_banner_1.jpg')",
          backgroundImage: `url(${bannerImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2} style={{ height: '55vh', alignItems: 'center' }}>
            <Grid item xs={12} md={7} lg={7}>
              <Typography
                variant="h3"
                component="h1"
                sx={{ mb: 1, textAlign: 'left', fontWeight: 600, color: "common.white" }}
              >
                {titleCopy}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
              >
                <b>{bodyCopy}</b>
              </Typography>
              <TextField
                id="hub-search"
                type="text"
                placeholder="Search"
                fullWidth
                sx={{ color: "#fff", backgroundColor: "#fff", borderRadius: '5px' }}
                //value={areaUnitsRequiredFilter}
                //onChange={e => setAreaUnitsRequiredFilter(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ mt: '40px' }}>
        <Grid
          container
          spacing={3}
        >
          <Grid item xs={12} md={4} lg={4}>
            <Grid
              container
              spacing={3}
              sx={{
                position: 'sticky',
                top: '65px'
              }}
            >
              <Grid item xs={12} md={12} lg={12}>
                <Card variant="outlined" sx={{ minHeight: 'calc(100vh - 110px)', borderColor: '#a9a9a9', borderRadius: '10px' }}>
                  <CardContent>
                    <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600, padding: '8px 16px' }}>
                      Filters
                    </Typography>
                    {/* <List>
                      <Collapse in={true} timeout="auto" unmountOnExit>
                        <List component="nav" aria-label="LPA Filter" sx={{ p: 2 }}>
                          <Autocomplete
                            disablePortal
                            id="lpa"
                            options={lpasAutocomplete}
                            renderInput={(params) => <TextField {...params} label="Local Planning Authority" />}
                            multiple
                            getOptionLabel={(option) => (option ? option.label : "")}
                          />
                        </List>
                      </Collapse>
                    </List> */}
                    <List>
                      <Collapse in={true} timeout="auto" unmountOnExit>
                        <List component="nav" aria-label="LPA Filter" sx={{ p: 2, pt: 0 }}>
                          <Autocomplete
                            disablePortal
                            id="nca"
                            options={nationalCharacterAreasAutocomplete}
                            renderInput={(params) => <TextField {...params} label="National Character Area" />}
                            multiple
                            getOptionLabel={(option) => (option ? option.label : "")}
                          />
                        </List>
                      </Collapse>
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Card
              variant="outlined"
              sx={{ borderRadius: '10px', mb: 4 }}
            //key={uuidv4()}
            >
              <CardActionArea
                component={Link}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4} key={uuidv4()}>
                    <CardMedia
                      component="img"
                      image="https://images.unsplash.com/photo-1573490636613-e0f99858e936?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="project"
                      height="100%"
                      //key={id}
                      //onLoad={() => setImageLoaded(true)}
                      sx={{ //visibility: imageLoaded ? 'visible' : 'hidden', 
                        maxHeight: '400px'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={8} key={uuidv4()}>
                    <CardContent sx={{ minHeight: '300px', pt: '20px' }}>
                      <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600, pt: '16px' }}>
                        New Forest Credits
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: '10px', fontSize: '16px', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.5 }}>
                        The area boundary stretches from where the river meets the sea just east of Bournemouth, north north west up to just north of Sixpenny Handley. It then travels due west to Shaftesbury before turning north to West Knoyle. It then travels west to Hardway before turning north east under Trowbridge and under Devizes (so both are excluded) to just north of Devizes. It then travels due west to Burbage.  It then drops due south back to the river mouth near Bournemouth. In this drop the boundary passes just to the east of Salisbury to include it within the catchment.
                      </Typography>
                      <Chip
                        label="Nutrient Credit"
                        color="primary"
                        variant="outlined"
                        icon={<IconPicker icon='droplet' color='primary' />}
                        key={uuidv4()}
                        sx={{ p: 2, mt: 2 }}
                      />
                    </CardContent>
                  </Grid>
                </Grid>
              </CardActionArea>
            </Card>
            <Card
              variant="outlined"
              sx={{ borderRadius: '10px' }}
            //key={uuidv4()}
            >
              <CardActionArea
                component={Link}
              //to={`/projects/${id}`}
              //state={{ project }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4} key={uuidv4()}>
                    <CardMedia
                      component="img"
                      image="https://www.projectsfornature.com/uploads/project_images/3d/94/1551803/1701957636_will_hawkes_bee_low_res.png"
                      alt="project"
                      height="100%"
                      //key={id}
                      //onLoad={() => setImageLoaded(true)}
                      sx={{ //visibility: imageLoaded ? 'visible' : 'hidden', 
                        maxHeight: '400px'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={8} key={uuidv4()}>
                    <CardContent sx={{ minHeight: '300px', pt: '20px' }}>
                      <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600, pt: '16px' }}>
                        Norfolk Credits
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: '10px', fontSize: '16px', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.5 }}>
                        Our first round of nutrient neutrality credits for the Yare catchment will be priced at £2,400+VAT per 0.1kg/yr Total Phosphorus (inclusive of the nitrogen mitigation needed for your development site). Payment for credits will be required upfront.

                        Not all the development in Norfolk is affected by nutrient neutrality requirements. Please see our map showing the areas affected.
                      </Typography>
                      <Chip
                        label="Nutrient Credit"
                        color="primary"
                        variant="outlined"
                        icon={<IconPicker icon='droplet' color='primary' />}
                        key={uuidv4()}
                        sx={{ p: 2, mt: 2 }}
                      />
                    </CardContent>
                  </Grid>
                </Grid>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default NutrientCredits