import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Stack, TextField } from '@mui/material';
import { nationalCharacterAreasAutocomplete } from '../../../shared/data/national-character-areas';
import LocalPlanningAuthorityService from '../../../shared/services/local-planning-authority.service';
import { useEffect, useState } from 'react';
import HubCustomListService from '../../../shared/services/hub-custom-list-service';

export const DialogEditProjectAdmin = (
  props
) => {

  const hubCustomListService = new HubCustomListService();
  const localPlanningAuthorityService = LocalPlanningAuthorityService.getInstance();
  const [lpas, setLpas] = useState();
  const [customRegionList, setCustomRegionList] = useState();

  const [updatedProject, setUpdatedProject] = useState({
    p_summary: '',
    p_description: '',
    p_local_planning_authority: '',
    p_national_character_area: '',
    group_at_project_level: '',
    bng_registration_number: '',
    custom_region_tag: ''
  })

  useEffect(() => {
    if (props.projectDetails) {
      setUpdatedProject({
        p_summary: props.projectDetails.p_summary,
        p_description: props.projectDetails.p_description,
        p_local_planning_authority: props.projectDetails.p_local_planning_authority,
        p_national_character_area: props.projectDetails.p_national_character_area,
        group_at_project_level: props.projectDetails.group_at_project_level,
        bng_registration_number: props.projectDetails.bng_registration_number,
        custom_region_tag: props.projectDetails.custom_region_tag
      })
    }

      hubCustomListService.getCustomRegionList(true)
        .then(response => {
          setCustomRegionList(response.data);
        });
    
  }, [props.editFormActive])

  const handleSetUpdatedProject = (event) => {
    setUpdatedProject({...updatedProject, [event.target.name]: event.target.value})
  }

  const handleUpdateList = (formControl, value) => {
    setUpdatedProject({ ...updatedProject, [formControl]: value})
  }

  useEffect(() => {
    localPlanningAuthorityService.getLpas()
      .then(response => {
        setLpas(response);
      });
  }, []);

  const handleUpdate = () => {
    props.onSubmit(updatedProject)
  }

  return (
    <Dialog open={props.editFormActive}  maxWidth='md' fullWidth>
      <DialogTitle sx={{ p: 4, pb: 2, }}>Edit Project Information</DialogTitle>
      <DialogContent sx={{ pt: 0, p: 4 }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <FormControl fullWidth margin='normal'>
            <TextField
              fullWidth
              className="formField"
              label="Title"
              name="p_summary"
              value={updatedProject.p_summary}
              onChange={handleSetUpdatedProject}
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <TextField
              fullWidth
              className="formField"
              label="Description"
              name="p_description"
              multiline
              rows={6}
              value={updatedProject.p_description}
              onChange={handleSetUpdatedProject}
            />
          </FormControl>
          {lpas && <FormControl fullWidth margin='normal'>
            <Autocomplete
              disablePortal
              name="p_local_planning_authority"
              options={lpas}
              getOptionLabel={(option) => option.lpa_name}
              inputValue={updatedProject.p_local_planning_authority}
              value={lpas?.find(lpa => lpa.lpa_name === updatedProject.p_local_planning_authority)}
              renderInput={(params) => <TextField {...params} label="Local Planning Authority" />}
              onInputChange={(e, value) => handleUpdateList('p_local_planning_authority', value.lpa_name)}
              onChange={(e, value) => handleUpdateList('p_local_planning_authority', value.lpa_name)}
            />
          </FormControl>}
          <FormControl fullWidth margin='normal'>
            <Autocomplete
              disablePortal
              name="p_national_charachter_area"
              options={nationalCharacterAreasAutocomplete}
              inputValue={updatedProject.p_national_character_area}
              value={updatedProject.p_national_character_area}
              renderInput={(params) => <TextField {...params} label="National Character Area" />}
              onInputChange={(e, value) => handleUpdateList('p_national_character_area', value)}
              onChange={(e, value) => handleUpdateList('p_national_character_area', value)}
            
            />
          </FormControl>

          {customRegionList && <Autocomplete
            disablePortal
            id="region"
            blurOnSelect={true}
            sx={{ mt: 2 }}
            options={customRegionList}
            renderInput={(params) => <TextField {...params} label="Add region" />}
            value={updatedProject?.custom_region_tag || ''}
            getOptionLabel={(option) => (option?.value ? option.value : option)}
            onChange={(e, value) => handleUpdateList('custom_region_tag', value.value)}
          />}

          <Grid item xs={12} md={12} lg={12} sx={{ mb: 2, mt: 2 }}>
            <TextField
              fullWidth
              className="formField"
              label="Biodiversity Gain Sites Register Number"
              name="bng_registration_number"
              placeholder="Biodiversity Gain Sites Register Number"
              // onChange={e => setProjectData('bng_registration_number', e.target.value)}
              value={updatedProject.bng_registration_number}
              onChange={handleSetUpdatedProject}
            />
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 4, pt: 0 }}>
        <Stack direction='row' pb={2} pr={2} spacing={2}>
          <Button onClick={props.onCancel} className="removeUppercase">Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleUpdate}
            className="removeUppercase"
          >
            Update
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};