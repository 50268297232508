import { Alert, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material'
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import SendIcon from '@mui/icons-material/Send';
import { useEffect, useState } from 'react';
import { Publish } from '@mui/icons-material';

export const AdminActionsBox = ({
  approval,
  setApproval,
  adminStatus,
  approve,
  reject,
  actionSuccess
}) => {

  const [showApprovalComments, setShowApprovalComments] = useState(false);
  const [errors, setErrors] = useState();
  const [sendEmail, setSendEmail] = useState(true);

  useEffect(() => {
    setShowApprovalComments(false);
    setSendEmail(true);
  }, [actionSuccess])

  const handleApprovalStatusSubmit = () => {
    switch (approval.outcome) {
      case 'APPROVE':
        approve(sendEmail)
        break;
      case 'REJECT':
        if (approval.comments) {
          reject(sendEmail)
        } else {
          setErrors('You must enter a comment when rejecting an application')
        }
        break;
      default:
        break
    }
  }

  const handleApprovalClick = (outcome) => {
    setApproval({ outcome: outcome });
    setShowApprovalComments(true);
  }

  const handleCancelApproval = () => {
    setApproval({ outcome: '', comments: '' });
    setShowApprovalComments(false);
  }

  return (
    <Grid container>
      <Grid item xs={7} md={7} lg={7} sx={{ mb: 2 }}>
        <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600, mb: 1 }}>
          Admin Actions
        </Typography>
      </Grid>
      <Grid item xs={5} md={5} lg={5} sx={{ mb: 2 }}>
        <Box>
          <Chip
            label={adminStatus?.status_name}
            variant="outlined"
            sx={{
              justifyContent: "flex-end",
              ml: "auto",
              float: "right",
              color: adminStatus?.color || "primary",
              borderColor: adminStatus?.color || "primary"
            }}
          />
        </Box>
      </Grid>
      {(adminStatus?.status_code === 'PENDING_REVIEW' || adminStatus?.status_code === 'APPROVED') && <Grid item xs={12} md={12} lg={12}>
        <Stack direction='row' spacing={1} sx={{ width: '100%', mb:2 }}>
          <Button
            color={adminStatus?.status_code === 'APPROVED' ? 'info' : undefined}
            variant={approval?.outcome === 'APPROVE' ? 'contained' : 'outlined'}
            onClick={() => handleApprovalClick('APPROVE')}
            className='removeUppercase'
            startIcon={adminStatus?.status_code === 'APPROVED' ? <Publish /> : <ThumbUpOutlinedIcon />}
            sx={{ flex: 1}}
            >
            {adminStatus?.status_code === 'APPROVED' ? 'Publish' : 'Approve'}
          </Button>
          {adminStatus?.status_code === 'PENDING_REVIEW' && <Button
            variant={approval?.outcome === 'REJECT' ? 'contained' : 'outlined'}
            onClick={() => handleApprovalClick('REJECT')}
            className='removeUppercase'
            startIcon={<ThumbDownOutlinedIcon />}
            sx={{ flex: 1 }}>
            Reject
          </Button>}
        </Stack>
        {showApprovalComments && <>
          {errors && <Alert severity='error' sx={{ mt: 2, mb: 1 }}>{errors}</Alert>}
          <TextField placeholder='Comment for the project owner...' multiline rows={6} onChange={(e) => setApproval({ ...approval, comments: e.target.value })} sx={{ width: '100%', mt: 2 }} />
          <FormControl margin="normal" fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  name='sendEmail'
                  checked={sendEmail}
                  value={approval?.comments || ''}
                  onClick={() => { setSendEmail(!sendEmail) }}
                />}
              label='Notify project owner via email'
            />
          </FormControl>
          <Stack direction='row' spacing={1} sx={{ float: 'right', mt: 2, mb: 2 }}>
            <Button className='removeUppercase'
              onClick={() => handleCancelApproval()}
              color={adminStatus?.status_code === 'APPROVED' ? 'info' : undefined}
              sx={{ float: 'right', mt: 2 }}>
              Cancel
            </Button>

            <Button variant='contained' sx={{ mt: 2, mb: 2 }}
              color={adminStatus?.status_code === 'APPROVED' ? 'info' : undefined}
              onClick={() => handleApprovalStatusSubmit()}
              className='removeUppercase'>
              {adminStatus?.status_code === 'APPROVED' ? 'Publish' : 'Submit'}
            </Button>
          </Stack>
        </>
        }
        {/* {!showApprovalComments &&
          <Stack direction='row' sx={{ width: '100%', mt: 1 }}>
            <Button variant={'outlined'} className='removeUppercase' startIcon={<SendIcon />} sx={{ flex: 1 }}>Send to approvers</Button>
          </Stack>
        } */}
      </Grid>}

      <Grid item xs={12} md={12} lg={12}>

        {/* {adminStatus?.status_code !== 'PENDING_REVIEW' &&
            <Alert severity="info">
              No admin actions available at this time.
            </Alert>
          } */}
      </Grid>

    </Grid>
  )
}