import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import httpClient from '../../../http/http-client';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { UserDetailsContext } from '../../../context/user-details';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { HubDetailsContext } from '../../../context/hub-details';
import CognitoUserAuthenticationService from '../../../services/cognito-user-authentication-service';
import { Settings } from '@mui/icons-material';

const RightNav = (props) => {
  const [user, setUser] = useState('');
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [isAlsoBuyer, setIsAlsoBuyer] = useState(false);
  const [hubHomePageUrl, setHubHomePageUrl] = useState('/home');
  const [isMainHub, setIsMainHub] = useState(false);

  const navigate = useNavigate();
  const userContext = useContext(UserDetailsContext);
  const hubDetailsContext = useContext(HubDetailsContext);
  const cognitoUserAuthenticationService = CognitoUserAuthenticationService.getInstance(hubDetailsContext.hubDetails?.cookie_domain);

  useEffect(() => {
    if (userContext.userDetails) {
      const user = userContext.userDetails;
      const initials = getInitials(user.name);
      user.initials = initials;
      setUser(user);
      setHasSubscription(user.hasSubscription);

      if (user.roles.includes('BUYER')) {
        setIsAlsoBuyer(true);
      }
    }
  }, [userContext]);

  useEffect(() => {
    if (hubDetailsContext.hubDetails) {
      setIsMainHub(hubDetailsContext.hubDetails.main_hub);
      setHubHomePageUrl(hubDetailsContext.hubDetails.home_page_url);
    }
  }, [hubDetailsContext]);

  const getInitials = (string) => {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const signOutCLick = async () => {

    cognitoUserAuthenticationService.logout(userContext.userDetails.username);
    userContext.clearUserDetails();
    navigate("/login");
  };

  const toggleDrawer = () => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    props.setRightNavOpen(false);
  };

  function goToSubscriptionSettings() {
    setLoadingSettings(true);
    if (hasSubscription) {
      httpClient.get('buyer/payment/customer-portal-session')
        .then(response => {
          window.open(response.data.data.url, '_self');
          setLoadingSettings(false);
        })
        .catch(() => {
          setLoadingSettings(false);
        });
    } else {
      navigate("/payment?upgrade=true");
    }

  }

  const list = () => (
    <Box
      sx={{
        width: props.open ? 300 : 0
      }}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'primary.main' }}>
              {user.initials}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={user.name} secondary={user.email} />
        </ListItem>
        <ListItem key='help-center' disablePadding>
          <Link
            to="https://help.gaiacompany.io"
            style={{
              width: '100%',
              color: '#111E21'
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <HelpOutlineIcon />
              </ListItemIcon>
              <ListItemText primary='Help centre' />
            </ListItemButton>
          </Link>
        </ListItem>
        {isAlsoBuyer && <ListItem key='swap-to-hub' disablePadding>
          <Link
            to={`${hubHomePageUrl}`}
            style={{
              width: '100%',
              color: '#111E21'
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <SwapHorizIcon />
              </ListItemIcon>
              <ListItemText primary={isMainHub ? 'Switch to marketplace' : 'Switch to home'} />
            </ListItemButton>
          </Link>
        </ListItem>}
        {isMainHub && <ListItem key='organisation1' disablePadding>
          <Link
            to={`/settings/organisation`}
            style={{
              width: '100%',
              color: '#111E21'
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary='Manage my organisation' />
            </ListItemButton>
          </Link>
        </ListItem>}
      </List>
      <Divider />
      <List>
        <ListItem key='sign-out' disablePadding>
          <ListItemButton
            onClick={() => signOutCLick()}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Sign out' />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={'right'}>
        <SwipeableDrawer
          id="website-right-nav-menu"
          anchor={'right'}
          open={props.open}
          onClose={toggleDrawer()}
          onOpen={toggleDrawer()}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

export default RightNav;