import React, { useContext, useEffect, useState } from 'react';
import { NavBarBrand } from "./nav-bar-brand";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { LoginButton } from "../../../components/buttons/login-button";
import { UserDetailsContext } from '../../../context/user-details';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Breadcrumb from '../../../components/presentation/breadcrumb';
import { HubDetailsContext } from '../../../context/hub-details';
import DropDownMenu from '../../../components/presentation/dropdown-menu';
import { AdminPanelSettings, AdminPanelSettingsOutlined, ChevronLeft, Dashboard, DashboardOutlined, Home, HomeOutlined, SpaceDashboard, West } from '@mui/icons-material';
import { Tooltip, useMediaQuery } from '@mui/material';
import gaiaTheme from '../../../../theme';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, leftNavHidden }) => ({
  //zIndex: theme.zIndex.drawer + 1,
  width: leftNavHidden ? '100%' : `calc(100% - 56px)`,
  //width: '100%',
  backgroundColor: '#ffffff',
  boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.14)',
  transition: leftNavHidden ? null : theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    // width: 'calc(100% - 240px)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function NavBar({ setRightNavOpen, useGaiaLogo = false, leftNavHidden, leftNavExpanded, isAdminPage = false }) {
  const [userInitials, setUserInitials] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moduleCodes, setModuleCodes] = useState([]);
  const [hideNav, setHideNav] = useState(true);
  const [homeUrl, setHomeUrl] = useState('/');
  const [isMainHub, setIsMainHub] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const isMobileMode = useMediaQuery(gaiaTheme.breakpoints.down('sm'));

  const open = Boolean(anchorEl);

  const user = useContext(UserDetailsContext);
  const hubContext = useContext(HubDetailsContext);

  useEffect(() => {
    if (user.userDetails) {
      const initials = getInitials(user.userDetails.name);
      setUserInitials(initials);
      setIsAdmin(user.userDetails.isAdmin);
      setHideNav(user.userDetails.userInformationRequired || (user.userDetails.roles.includes('ECOLOGIST') && !user.userDetails.roles.includes('SELLER')));
      setIsAuthenticated(true);
    }
  }, [user.change, user]);

  useEffect(() => {
    if (hubContext.hubDetails) {
      setHomeUrl(hubContext.hubDetails.home_page_url);
      setIsMainHub(hubContext.hubDetails.main_hub);
      const codes = [];
      hubContext.hubDetails.hubTiles?.forEach(tile => {
        codes.push(tile?.tile_code);
        setModuleCodes(codes);
      });
    }
  }, [hubContext.hubDetails]);

  const getInitials = (string) => {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const handleNewClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" sx={{ right: '0' }} leftNavHidden={leftNavHidden} open={leftNavExpanded}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}
      >
        <NavBarBrand useGaiaLogo={useGaiaLogo} />
        {/* {!isMainHub &&
          <Tooltip title='Seller Home' placement="bottom" arrow>
            <IconButton aria-label="dashboard" sx={{ color: 'text.primary', ml: 2 }} to='/dashboard' component={Link}>
              <Home />
            </IconButton>
          </Tooltip>
        } */}
        <Typography
          component="h1"
          variant="h6"
          color="text.primary"
          noWrap
          sx={{ flexGrow: 1, visibility: { xs: 'hidden', sm: 'visible' }, pl: 2 }}
        >
          <Breadcrumb blockNavigation />
        </Typography>

        {!isMainHub &&
          <Tooltip title='Buyer Home' placement="bottom" arrow>
            <IconButton aria-label="home" sx={{ color: '#000' }} to={homeUrl} component={Link}>
              <HomeOutlined />
            </IconButton>
          </Tooltip>
        }

        {isAdmin &&
          <Tooltip title={isAdminPage ? 'Switch to Seller' : 'Switch to Admin'} placement="bottom" arrow>
            <IconButton
              aria-label="admin"
              sx={{ ml: 1, color: '#000' }}
              to={isAdminPage ? '/dashboard' : '/admin/dashboard'}
              component={Link}
            >
              {isAdminPage ? <AdminPanelSettings /> : <AdminPanelSettingsOutlined />}
            </IconButton>
          </Tooltip>
        }

        {!isAuthenticated && (
          <>
            <LoginButton />
          </>
        )}
        {!hideNav && <>
          {moduleCodes.length > 0 && <Button
            variant="outlined"
            size="medium"
            disableElevation
            startIcon={<AddBoxOutlinedIcon />}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={handleNewClick}
            className="removeUppercase"
            sx={{
              mr: "20px",
              color: '#111E21',
              borderColor: '#111E21',
              ml: 2
            }}
          >
            {isMobileMode ? '' : 'New'}
          </Button>}
          <DropDownMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {moduleCodes.includes('BNG_MARKETPLACE') && <MenuItem
              onClick={handleClose}
              disableRipple
              component={Link}
              to="/projects/new"
            >
              <SpaOutlinedIcon />
              New listing
            </MenuItem>}
            {moduleCodes.includes('INVESTMENT_PROJECT') && <MenuItem
              onClick={handleClose}
              disableRipple
              component={Link}
              to="/investment-project/new"
            >
              <BookmarkBorderIcon />
              New Project for Funding
            </MenuItem>}
            {isAdmin && moduleCodes.includes('LEARNING_HUB') && <MenuItem
              onClick={handleClose}
              disableRipple
              component={Link}
              to="/admin/articles/new"
            >
              <ArticleOutlinedIcon />
              New article
            </MenuItem>}
          </DropDownMenu>

        </>}
        {isAuthenticated && (
          <IconButton
            onClick={() => setRightNavOpen(true)}
            sx={{ p: 0 }}
          >
            <Avatar sx={{ bgcolor: 'primary.main' }}>{userInitials}</Avatar>
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;