import React, { useContext, useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { HubDetailsContext } from '../../../shared/context/hub-details';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import httpClient from '../../../shared/http/http-client';
import LearningMenu from "../../../shared/components/articles/learning-menu";
import ArticleGroupService from "../../../shared/services/article-group-service";
import ArticleCard from '../../../shared/components/articles/article-card';
import { useLocation, useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

const Learning = () => {
  const articleGroupService = new ArticleGroupService();

  const [articleGroups, setArticleGroups] = useState([]);
  const [articles, setArticles] = useState([]);
  const [activeGroup, setActiveGroup] = useState();
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [titleCopy, setTitleCopy] = useState("");
  const [bodyCopy, setBodyCopy] = useState("");
  const [searchTerm, setSearchTerm] = useState();
  const [noArticles, setNoArticles] = useState(false);

  const hub = useContext(HubDetailsContext);
  const params = useParams();
  const location = useLocation();

  const getArticleGroups = () => {
    articleGroupService.getAllArticleGroups()
      .then(response => {
        setArticleGroups(response);
        setArticles(response[0].articles);
        setActiveGroup(response.find(group => !group.hide_from_learning_hub)?.id)
      })
  }

  useEffect(() => {
    getArticleGroups();
  }, []);

  useEffect(() => {
    getArticleGroups();
  }, [location])

  useEffect(() => {

    if (params.groupCode) {
      setArticles([]);
      setActiveGroup(parseInt(params.groupCode))
    }

    if (activeGroup) {
      // setArticles([]);
      // articleGroupService.getOneArticleGroup(activeGroup)
      //   .then(response => {
      //     setArticles(response.articles);
      //   })
      const articles = articleGroups.find(group => group.id === activeGroup)?.articles;
      if (articles) {
        setArticles(articles)
      }

    }
  }, [location, activeGroup]);

  useEffect(() => {
    if (searchTerm?.length > 2 || searchTerm?.length === 0) {
      setNoArticles(false);
      const delayDebounceFn = setTimeout(() => {
        articleGroupService.searchAllArticles(searchTerm)
          .then(response => {
            if (response.length) {
              setArticleGroups(response);
              setArticles(response[0].articles);
              setActiveGroup(response[0].id)
            } else {
              setActiveGroup(undefined)
              setArticleGroups([])
              setArticles([])
              setNoArticles(true)
            }

          })
      }, 800)

      return () => clearTimeout(delayDebounceFn)

    }

  }, [searchTerm])


  useEffect(() => {
    httpClient.get('data/hub/module-style/learning')
      .then(moduleStyle => {
        if (moduleStyle.data[0].banner_image_url) {
          setBannerImageUrl(moduleStyle.data[0]?.banner_image_url)
        } else {
          setBannerImageUrl(hub.hubDetails.hub_styles.banner_image_url)
        }
        setTitleCopy(moduleStyle.data[0]?.title_copy ? moduleStyle.data[0].title_copy : "Learning hub");
        setBodyCopy(moduleStyle.data[0]?.body_copy ? moduleStyle.data[0].body_copy : "Browse learning resources and case studies");

      })
  }, [hub])

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    window.analytics.page();
  }, []);

  return (
    <>
      <Toolbar />
      <Box
        sx={{
          height: '45vh',
          mt: 2,
          backgroundImage: `url(${bannerImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2} style={{ height: '45vh', alignItems: 'center' }}>
            <Grid item xs={12} md={7} lg={7}>
              <Typography
                variant="h3"
                component="h1"
                sx={{ mb: 2, textAlign: 'left', fontWeight: 600, color: "common.white" }}
              >
                {!params.groupCode ? titleCopy : articleGroups?.find(group => group.id === parseInt(params.groupCode))?.title_copy}
              </Typography>

              <Typography
                variant="h6"
                sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
              >
                {!params.groupCode ? bodyCopy : articleGroups?.find(group => group.id === parseInt(params.groupCode))?.subtitle_copy}
              </Typography>
              {!params.groupCode &&
                <TextField
                  id="hub-search"
                  type="text"
                  placeholder="Start typing to search..."
                  fullWidth
                  onChange={handleSearch}
                  sx={{ color: "#fff", backgroundColor: "#fff", borderRadius: '5px' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              }
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ mt: '40px', pb: '40px' }}>
        <Grid container spacing={4}>
          {!params.groupCode && <Grid item xs={12} md={4} lg={4}>
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                position: 'sticky',
                top: '90px',
                height: 'calc(100vh - 130px)',
                borderColor: '#a9a9a9',
                borderRadius: '10px',
                overflow: 'auto'
              }}
            >
              <LearningMenu
                routeToArticle={'/hub/learning/'}
                articleGroups={articleGroups}
                activeGroup={activeGroup}
                setActiveGroup={setActiveGroup}
                noGroupsFound={noArticles}
              />
            </Paper>
          </Grid>}
          <Grid item xs={12} md={params.groupCode ? 12 : 8} lg={params.groupCode ? 12 : 8}>
            {noArticles && <Alert severity="info" sx={{ mb: 4, p: 2 }}>
              No articles found matching your current search.
            </Alert>}
            <Grid container spacing={2}>
              {articles && articles.map((article, index) => {
                return <Grid item xs={12} md={params.groupCode ? 4 : 6} lg={params.groupCode ? 4 : 6} key={index}>
                  <ArticleCard
                    routeToArticle={`/hub/learning/${article.id}`}
                    article={article}
                    elevated={true}
                  />
                </Grid>
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>

    </>
  );
};

export default Learning