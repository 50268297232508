import { Box, Card, CardActionArea, CardContent, Divider, Grid, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import httpClient from '../../../shared/http/http-client';
import { HubDetailsContext } from '../../../shared/context/hub-details';
import IconPicker from '../../../shared/components/icon-picker';

const Modules = () => {
  const hubContext = useContext(HubDetailsContext);

  const [tiles, setTiles] = useState([]);

  function getAvailableTiles() {
    httpClient.get('admin/hub/available-tiles')
      .then(response => {
        let newTiles = [];
        let config_path, config_title, config_subtitle, config_icon;
        response.data.forEach(tile => {
          switch (tile.tile_code) {
            case 'INVESTMENT_PROJECT':
              config_title = 'Investment Projects';
              config_subtitle = 'Configuration of the investment projects module';
              config_path = '/admin/configuration/modules/invest';
              config_icon = 'investment';
              break;
            case 'BNG_MARKETPLACE':
              config_title = 'Natural Capital Marketplace';
              config_subtitle = 'Configuration of the marketplace module';
              config_path = '/admin/configuration/modules/marketplace';
              config_icon = 'marketplace';
              break;
            case 'LEARNING_HUB':
              config_title = 'Learning Hub';
              config_subtitle = 'Configuration of the learning hub module';
              config_path = '/admin/configuration/modules/learning';
              config_icon = 'learning';
              break;
            case 'NUTRIENT_NEUTRALITY':
              config_title = 'Nutrient Neutrality';
              config_subtitle = 'Configuration of the nutrient neutrality module';
              config_path = '/admin/configuration/modules/nutrient';
              config_icon = 'category';
              break;
            default:
              break;
          }

          return newTiles.push({
            ...tile,
            config_path: config_path,
            config_title: config_title,
            config_subtitle: config_subtitle,
            config_icon: config_icon
          });
        });
        setTiles(newTiles);
        hubContext.setChange(hubContext.change + 1);
      });
  }

  useEffect(() => {
    getAvailableTiles();
  }, []);

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
        <Typography variant="h5" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
          Modules
        </Typography>
      </Stack>
      <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
      {/* <ModulesForm
        toggleActiveTile={toggleActiveTile}
        tiles={tiles}
        setTiles={setTiles}
      /> */}
      <Grid container spacing={2}>
        {tiles?.map((tile) => {
          return (
            <Grid item xs={12} md={6} lg={4} key={tile.id}>
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'auto',
                  minHeight: '100%',
                  border: tile.is_activated_by_hub ? '1px solid #70942c' : '1px solid gray',
                }}
              >
                <CardActionArea
                  component={Link}
                  state={{ tile }}
                  to={tile.config_path}
                  sx={{
                    p: 2,
                    flex: 1
                  }}
                >
                  <CardContent>
                    <ListItem sx={{ alignItems: 'start', p: 0 }}>
                      <ListItemIcon sx={{ minWidth: '45px', pt: '2px' }}>
                        <IconPicker icon={tile.config_icon} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ mt: 0 }}
                        primary={tile.config_title}
                        secondary={tile.config_subtitle}
                      />
                    </ListItem>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        }
        )}
      </Grid>
    </Box>
  );
};

export default Modules; 