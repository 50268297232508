import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import { v4 as uuidv4 } from 'uuid';
import { Box, CardActionArea, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import TollIcon from '@mui/icons-material/Toll';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import GrassOutlinedIcon from '@mui/icons-material/GrassOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import WaterIcon from '@mui/icons-material/Water';
import LockIcon from '@mui/icons-material/Lock';
import { HubDetailsContext } from '../context/hub-details';
import { Verified } from '@mui/icons-material';

const ProjectCard = ({
  cardType,
  project,
  id,
  summary,
  primaryImage,
  primaryImageURL,
  location,
  LPA,
  NCA,
  totalBiodiversityUnits,
  totalAreaHabitats,
  totalHedgerowHabitats,
  totalWatercourseHabitats,
  totalPrice,
  hidePrice,
  freeTier,
  shallow_status,
  isMobileMode,
  bngRegistered
}) => {

  const hub = useContext(HubDetailsContext);
  const [routeToProject, setRouteToProject] = useState('projects');

  useEffect(() => {
    // const route = hub.hubDetails.main_hub ? 'projects' : 'hub/projects'
    const route = 'hub/projects';
    setRouteToProject(route);
  }, [hub]);

  const [imageLoaded, setImageLoaded] = useState(false);
  const types = [];
  let imagePath;
  if (primaryImage) {
    imagePath = primaryImageURL;
  } else {
    imagePath = 'https://ychef.files.bbci.co.uk/976x549/p0b6bt6f.jpg';
  }

  if (totalAreaHabitats > 0) {
    types.push('Area');
  }

  if (totalHedgerowHabitats > 0) {
    types.push('Hedgerow');
  }

  if (totalWatercourseHabitats > 0) {
    types.push('Watercourse');
  }

  if (cardType === 'standard') {
    return (
      <Card
        variant="outlined"
        // sx={{ borderRadius: '10px', minHeight: '100%', maxWidth: isMobileMode ? '310px' : '100%', width: '100%' }}
        sx={{ borderRadius: '10px', minHeight: '100%', maxWidth: '100%', width: '100%', display: 'flex' }}
        key={uuidv4()}
      >
        <CardActionArea
          component={Link}
          to={`/${routeToProject}/${id}`}
          state={{ project }}
        >
          <CardMedia
            component="img"
            height="200"
            image={imagePath}
            alt="project"
            key={id}
            onLoad={() => setImageLoaded(true)}
            sx={{ visibility: imageLoaded ? 'visible' : 'hidden', maxHeight: '305px' }}
          />
          <Chip
            label={shallow_status?.status_name || 'Available'}
            variant="outlined"
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              backgroundColor: '#fff',
              color: shallow_status?.color || '#70942c',
              borderColor: shallow_status?.color || '#70942c'
            }}
          />

          {bngRegistered && <Box sx={{
            borderRadius: '50%',
            margin: '-3px',
            backgroundColor: 'white',
            width: '10px',
            height: '10px',
            position: "absolute",
            top: 20,
            right: 20,
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Tooltip title="BNG registered" placement="right" arrow>
              <Verified
                sx={{
                  color: '#1DA1F2',

                }} />
            </Tooltip>
          </Box>}

          <CardContent sx={{ position: 'relative' }}>

            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
              <Typography
                variant="h6"
                color="text.primary"
                sx={{
                  mr: 1,
                  fontWeight: 600,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'initial',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  minHeight: '41px'
                  // whiteSpace: 'nowrap',
                  // overflow: 'hidden',
                  // textOverflow: 'ellipsis'
                }}>
                {summary}
              </Typography>
              {project.enhanced_branding && <Stack alignItems={'center'} >
                <img
                  src={project.enhanced_branding.logo_url}
                  alt="logo"
                  style={{ maxHeight: '50px', maxWidth: "80px", objectFit: 'contain', paddingLeft: 1 }}
                />
              </Stack>}
            </Stack>

            <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {!freeTier ? (LPA) : (
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Skeleton animation={false} width='90%' height='40px' />
                  <LockIcon />
                </Stack>

              )}
            </Typography>

            <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
              <TollIcon color="primary" />
              {totalBiodiversityUnits !== 0 && <Typography variant="p" color="text.secondary">
                <span style={{ lineHeight: '24px' }}>
                  <b>Units available: </b> <NumericFormat value={totalBiodiversityUnits} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                </span>
              </Typography>}
              {totalBiodiversityUnits === 0 && <Typography variant="p" color="text.secondary">
                <span style={{ lineHeight: '24px' }}>
                  <b>Units available: </b>
                  TBC
                </span>
              </Typography>}
            </Stack>

            <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px', alignItems: 'center' }}>
              <SellOutlinedIcon color="primary" />

              <Typography variant="p" color="text.secondary">
                {(!hidePrice && totalPrice !== "0") && <Stack direction={'row'} alignItems={'center'}>
                  <NumericFormat value={Math.round(project.lowestPriceUnit)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                  {project.lowestPriceUnit !== project.highestPriceUnit && <>&nbsp;-&nbsp;</>}
                  {project.lowestPriceUnit !== project.highestPriceUnit && <NumericFormat value={Math.round(project.highestPriceUnit)} displayType={'text'} thousandSeparator={true} prefix={'£'} />}
                </Stack>}

                {(hidePrice || totalPrice === "0") && <Stack direction={'row'} alignItems={'center'}>
                  POA
                </Stack>}

              </Typography>


            </Stack>
            <Divider />
            <Stack direction="row" sx={{ mt: '20px', mb: '20px', maxWidth: '100%', flexWrap: 'wrap', minHeight: '40px' }}>
              {types?.map((type) => {
                let icon;
                switch (type) {
                  case 'Area':
                    icon = <SpaOutlinedIcon />;
                    break;
                  case 'Hedgerow':
                    icon = <GrassOutlinedIcon />;
                    break;
                  case 'Watercourse':
                    icon = <WaterIcon />;
                    break;
                  default:
                    icon = <SpaOutlinedIcon />;
                    break;
                }
                return (
                  <Chip
                    sx={{ mr: 1, mt: 1 }}
                    label={type}
                    color="primary"
                    variant="outlined"
                    icon={icon}
                    key={uuidv4()}
                  />

                );
              }
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card >
    );
  }

  if (cardType === 'wide') {
    return (
      <Card
        variant="outlined"
        sx={{ borderRadius: '10px', height: '100%' }}
        key={uuidv4()}
      >
        <CardActionArea
          component={Link}
          to={`/${routeToProject}/${id}`}
          state={{ project }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4} key={uuidv4()}>
              <CardMedia
                component="img"
                image={imagePath}
                alt="project"
                height="100%"
                key={id}
                onLoad={() => setImageLoaded(true)}
                sx={{ visibility: imageLoaded ? 'visible' : 'hidden', height: '305px', maxHeight: '305px' }}
              />
              <Chip
                label={shallow_status?.status_name || 'Available'}
                variant="outlined"
                //icon={<CircleIcon style={{ fontSize: '6px', color: shallow_status?.color || 'primary' }} />}
                sx={{
                  position: "absolute",
                  top: 25,
                  left: 10,
                  backgroundColor: '#fff',
                  color: shallow_status?.color || '#70942c',
                  borderColor: shallow_status?.color || '#70942c'
                }}
              />

              {bngRegistered && <Box sx={{
                borderRadius: '50%',
                margin: '-3px',
                backgroundColor: 'white',
                width: '10px',
                height: '10px',
                position: "absolute",
                top: 38,
                left: 208,
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <Tooltip title="BNG registered" placement="right" arrow>
                  <Verified
                    sx={{
                      color: '#1DA1F2',

                    }} />
                </Tooltip>
              </Box>}


            </Grid>
            <Grid item xs={12} md={6} lg={8} key={uuidv4()}>
              <CardContent>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="h6" color="text.primary" sx={{
                    fontWeight: 600, whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    {summary}
                  </Typography>
                  {project.enhanced_branding && <Stack alignItems={'center'} >
                    <img
                      src={project.enhanced_branding.logo_url}
                      alt="logo"
                      style={{ maxHeight: '50px', maxWidth: "100px", objectFit: 'contain', paddingLeft: 1 }}
                    />
                  </Stack>}
                </Stack>

                <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {!freeTier ? (LPA) : (
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Skeleton animation={false} width='90%' height='40px' />
                      <LockIcon />
                    </Stack>
                  )}
                </Typography>
                <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                  <TollIcon color="primary" />
                  {totalBiodiversityUnits !== 0 && <Typography variant="p" color="text.secondary">
                    <span style={{ lineHeight: '24px' }}>
                      <b>Units available: </b>
                      <NumericFormat value={totalBiodiversityUnits} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                    </span>
                  </Typography>}
                  {totalBiodiversityUnits === 0 && <Typography variant="p" color="text.secondary">
                    <span style={{ lineHeight: '24px' }}>
                      <b>Units available: </b>
                      TBC
                    </span>
                  </Typography>}
                </Stack>

                <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px', alignItems: 'center' }}>
                  <SellOutlinedIcon color="primary" />

                  <Typography variant="p" color="text.secondary">
                    {(!hidePrice && totalPrice !== "0") && <Stack direction={'row'} alignItems={'center'}>
                      <NumericFormat value={Math.round(project.lowestPriceUnit)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                      {project.lowestPriceUnit !== project.highestPriceUnit && <>&nbsp;-&nbsp;</>}
                      {project.lowestPriceUnit !== project.highestPriceUnit && <NumericFormat value={Math.round(project.highestPriceUnit)} displayType={'text'} thousandSeparator={true} prefix={'£'} />}
                    </Stack>}

                    {(hidePrice || totalPrice === "0") && <Stack direction={'row'} alignItems={'center'}>
                      POA
                    </Stack>}

                  </Typography>


                </Stack>
                <Divider />
                <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                  {types?.map((type) => {
                    let icon;
                    switch (type) {
                      case 'Area':
                        icon = <SpaOutlinedIcon />;
                        break;
                      case 'Hedgerow':
                        icon = <GrassOutlinedIcon />;
                        break;
                      case 'Watercourse':
                        icon = <WaterIcon />;
                        break;
                      default:
                        icon = <SpaOutlinedIcon />;
                        break;
                    }
                    return (
                      <Chip
                        label={type}
                        color="primary"
                        variant="outlined"
                        icon={icon}
                        key={uuidv4()}
                      />
                    );
                  }
                  )}
                </Stack>
              </CardContent>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    );
  }
};

export default ProjectCard;