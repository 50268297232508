import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Alert } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import 'moment/locale/en-gb';

const DialogAddProductAddOnRecord = (
  { active, onCancel, record, onSubmit }
) => {

  const [localRecord, setLocalRecord] = useState({});
  const [hasError, setHasError] = useState(false);
  const [locale, setLocale] = useState('en-GB');

  useEffect(() => {
    setHasError(false);
    if (record) {
      setLocalRecord(record);
    } else {
      setLocalRecord({});
    }
  }, [active]);

  const handleSubmit = () => {
    setHasError(false);
    console.log(localRecord);
    if (localRecord.email && localRecord.expiry_date && localRecord.activated_date) {
      onSubmit(localRecord);
    } else {
      setHasError(true);
    }
  };

  const handleChange = (e) => {
    setLocalRecord({ ...localRecord, [e.target.name]: e.target.value });
  };

  const handleUpdate = (formControl, value) => {
    setLocalRecord({ ...localRecord, [formControl]: value });
  };

  return (
    <Dialog open={active} >
      <DialogTitle sx={{ pb: '5px' }}>{record ? 'Edit record' : 'Add record'}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: '10px' }}>
          Enter details regarding this record.
        </DialogContentText>

        {hasError && <Alert severity='error'>Please enter all the required information below.</Alert>}
        <Box
          component="form"
          noValidate
          autoComplete="off">
          {localRecord && <>
            <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
              <InputLabel htmlFor="total-price">User email*</InputLabel>
              <OutlinedInput
                fullWidth
                className="formField"
                name='email'
                label="User email*"
                type="text"
                value={localRecord.email || ''}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl fullWidth margin='normal'>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                {localRecord.activated_date && <DatePicker
                  minDate={moment(new Date())}
                  label="Activation date*"
                  emptyLabel="DD/MM/YYYY"
                  value={moment(localRecord.activated_date)}
                  onChange={value => handleUpdate('activated_date', value.format('yyyy-MM-DD'))}
                />}

                {!localRecord.activated_date && <DatePicker
                  minDate={moment(new Date())}
                  label="Activation date*"
                  emptyLabel="DD/MM/YYYY"
                  onChange={value => handleUpdate('activated_date', value.format('yyyy-MM-DD'))}
                />}
              </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth margin='normal'>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                {localRecord.expiry_date && <DatePicker
                  minDate={moment(new Date())}
                  label="Expiry date*"
                  emptyLabel="DD/MM/YYYY"
                  value={moment(localRecord.expiry_date)}
                  onChange={value => handleUpdate('expiry_date', value.format('yyyy-MM-DD'))}
                />}

                {!localRecord.expiry_date && <DatePicker
                  minDate={moment(new Date())}
                  label="Expiry date*"
                  emptyLabel="DD/MM/YYYY"
                  onChange={value => handleUpdate('expiry_date', value.format('yyyy-MM-DD'))}
                />}
              </LocalizationProvider>
            </FormControl>


            <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
              <InputLabel htmlFor="total-price">Price</InputLabel>
              <OutlinedInput
                fullWidth
                className="formField"
                name='price'
                label="Price"
                type="number"
                step="0.1"
                value={localRecord.price || ''}
                onChange={handleChange}
              />
            </FormControl>
          </>}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
        <Button onClick={() => onCancel()} className="removeUppercase">Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleSubmit}
          className="removeUppercase">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAddProductAddOnRecord;