import React, { useContext, useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Button, Chip, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import AdminProductAddOnService from '../../services/admin-product-add-on.service';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { Add, CancelOutlined } from '@mui/icons-material';
import DialogAddProductAddOnRecord from '../../components/dialogs/dialog-add-product-add-on-recors';
import { UserInformationProviderContext } from '../../../shared/context/user-information-provider';
import moment from 'moment';

const AdminProductAddOnDetail = () => {

  const adminProductAddOnService = new AdminProductAddOnService();
  const params = useParams();
  const userInformationProviderContext = useContext(UserInformationProviderContext);
  const [addOn, setAddOn] = useState();
  const [dialogActive, setDialogActive] = useState(false);


  useEffect(() => {
    getAddOn();
  }, []);

  const getAddOn = () => {
    adminProductAddOnService.getAddOn(params.code)
      .then(response => {
        setAddOn(response);
      });
  };

  const onDialogCancel = () => {
    setDialogActive(false);
  };

  const onDialogSubmit = (record) => {
    const formatted = {
      ...record,
      code: params.code
    };
    adminProductAddOnService.activateNewRecord(formatted)
      .then(() => {
        setDialogActive(false);
        getAddOn();
        userInformationProviderContext.openSnackbar({ open: true, message: 'Add on activated.', backgroundColor: 'green' });
      })
      .catch(err => {
        setDialogActive(false);
        userInformationProviderContext.openDialog({
          active: true,
          title: 'Error',
          message: err?.response?.data?.message,
          hideCancelButton: true
        });
      });
  };

  const handleCancelAddOn = (id) => {
    userInformationProviderContext.openDialog({
      active: true,
      title: 'Are you sure?',
      message: 'Please confirm this add on should be cancelled.',
    })
      .then(response => {
        if (response) {
          adminProductAddOnService.disableRecord(id)
            .then(() => {
              getAddOn();
              userInformationProviderContext.openSnackbar({ open: true, message: 'Add-on deactivated.', backgroundColor: 'green' });
            });
        }
      });
  };

  const addNewRecord = () => {
    setDialogActive(true);
  };

  return (
    <>
      <Toolbar />
      <DialogAddProductAddOnRecord
        active={dialogActive}
        onCancel={onDialogCancel}
        onSubmit={onDialogSubmit}>

      </DialogAddProductAddOnRecord>
      <Container maxWidth="md">
        {addOn && <Paper sx={{ p: 4, pb: 12, mt: 2 }}>
          <Typography variant="h5" color="text.primary">
            Manage Add On: {addOn.name}
          </Typography>
          <Divider style={{ margin: '20px 0px' }} />

          <Stack direction={'row'} justifyContent={'flex-end'}>
            <Button
              variant={'outlined'}
              disableElevation
              className="removeUppercase"
              startIcon={<Add />}
              onClick={() => addNewRecord()}
              sx={{ minWidth: '90px' }}
            >
              Add new record
            </Button>
          </Stack>
          <Table>
            <TableHead>
              <TableRow sx={{ fontWeight: 600 }}>
                <TableCell sx={{ fontWeight: 600 }}></TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Organisation</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>User name</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Activated</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Expiry</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Price</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Cancel</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addOn.activated_add_ons?.map((activated, index) => {
                return <>
                  <TableRow
                    key={index}>
                    <TableCell>{activated.active ? new Date(activated.expiry_date).getTime() < Date.now() ? <Chip label={'Expired'} color='info' /> : <Chip label={'Active'} color='success' /> : <Chip label={'Cancelled'} color='error' />}</TableCell>
                    <TableCell>{activated.user.organisation}</TableCell>
                    <TableCell>{activated.user.name}</TableCell>
                    <TableCell>{moment(activated.activation_date).format('DD/MM/yyyy')}</TableCell>
                    <TableCell>{moment(activated.expiry_date).format('DD/MM/yyyy')}</TableCell>
                    <TableCell><NumericFormat value={activated.price} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'£'} /></TableCell>
                    <TableCell>
                      {new Date(activated.expiry_date).getTime() > Date.now() && <IconButton onClick={() => handleCancelAddOn(activated.id)}><CancelOutlined /></IconButton>}
                    </TableCell>
                  </TableRow>
                </>;
              })}

              {!addOn.activated_add_ons.length &&
                <TableRow>
                  <TableCell colSpan={10}>No records</TableCell>
                </TableRow>}
            </TableBody>
          </Table>
        </Paper>}
      </Container>
    </>
  );
};

export default AdminProductAddOnDetail;