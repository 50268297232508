import { Download, DownloadOutlined, SpaOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';

export const DownloadResourceButton = ({ route, buttonCopy }) => {

  const handleClick = () => {
    // window.open(route, '_blank');
    window.location.assign(route);
    console.log(route)
  }

  return (<>
    <Button
      variant="contained"
      color="primary"
      size="large"
      disableElevation
      className='removeUppercase'
      startIcon={<Download />}
      onClick={() => handleClick()}
      sx={{ width: '100%' }}>
      {buttonCopy}
    </Button>
  </>)
}