import { useContext, useEffect, useState } from 'react';
import { nationalCharacterAreasAutocomplete } from '../../shared/data/national-character-areas';
import { Autocomplete, TextField, useMediaQuery } from '@mui/material';
import gaiaTheme from '../../theme';
import { HubDetailsContext } from '../../shared/context/hub-details';
import * as Habitats from '../../shared/data/habitats';
import HubCustomListService from '../../shared/services/hub-custom-list-service';

const unitTypes = [
  {
    label: 'Area',
    filterName: 'unitType',
    group_id: 3,
    group: 'Habitat Type'
  },
  {
    label: 'Hedgerow',
    filterName: 'unitType',
    group_id: 3,
    group: 'Habitat Type'
  },
  {
    label: 'Watercourse',
    filterName: 'unitType',
    group_id: 3,
    group: 'Habitat Type'
  }
]

const BuyerSearch = (props) => {
  console.log(props)
  const lpas = props.lpas?.map(lpa => ({ label: lpa.lpa_name, filterName: 'lpa', group: 'Local Planning Authority' }))
  const ncas = nationalCharacterAreasAutocomplete.map(nca => ({ ...nca, filterName: 'nca', group: 'National Character Area' }))
  const broadHabitats = Habitats.broadHabitatsAutocomplete.map(broadHabitat => ({ ...broadHabitat, filterName: 'broadHabitat', group: 'Broad Habitat' }))
  const [optionsArray, setOptionsArray] = useState([])
  let id = 1;
  const isMobileMode = useMediaQuery(gaiaTheme.breakpoints.down('sm'));

  const hub = useContext(HubDetailsContext);
  const hubCustomListService = new HubCustomListService();

  useEffect(() => {
    hubCustomListService.getCustomRegionList(true)
      .then(response => {
        const newRegions = response.data.map(region => ({ label: region.value, filterName: 'region', group: 'Region', group_id: 5 }))
        if (response.custom_list_retreived) {
          setOptionsArray([...newRegions, ...unitTypes, ...broadHabitats].map(option => ({ ...option, key: id++ })))
        } else {
          setOptionsArray([...newRegions, ...unitTypes, ...lpas, ...ncas, ...broadHabitats].map(option => ({ ...option, key: id++ })))
        }
      })
  }, [hub])

  return (
    <>
      {lpas && <Autocomplete
        disablePortal
        id="buyerSearch"
        freeSolo
        blurOnSelect={true}
        clearOnEscape
        autoHighlight
        options={optionsArray}
        groupBy={(option) => option.group}
        renderInput={(params) => <TextField {...params} label="Enter a location or habitat type" />}
        onChange={(e, option) => props.handleSetFilter(option?.filterName, option?.label)}
        onInputChange={(e, value) => props.setSearchInputValue(value)}
        inputValue={props.searchInputValue}
        value={props.searchInputValue}
      />}
      </>
    
  )
}

export default BuyerSearch;