import React, { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";
import { Typography, Grid, Link as MuiLink, Accordion, AccordionSummary, List, ListItem, ListItemText, ListItemIcon, AccordionDetails, Select, MenuItem, Checkbox, Stack, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconPicker from '../../../../shared/components/icon-picker';
import BrandingButtonForm from './button-form';
import OrganisationSettingsForm from './organisation-settings-form';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FooterSettingsForm from './footer-settings-form';
import { ImageFileUpload } from '../../../../shared/components/image-file-upload';
import HubAdminService from '../../../services/hub-admin-service';

const BrandingForm = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [fileData, setFileData] = useState([]);

  const hubAdminService = new HubAdminService();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const saveCompanyLogo = () => {
    const form = new FormData();
    form.append("logo", fileData[0]);

    hubAdminService.updateCompanyLogo(form)
      .then(() => {
        setFileData([]);
        props.onLogoUpdate();
      });
  };

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <Accordion
          key={0}
          expanded={expanded === `panel-${0}`}
          onChange={handleChange(`panel-${0}`)}
          elevation={0}
          sx={{
            border: `1px solid rgba(0, 0, 0, 0.12)`, '&:not(:last-child)': {
              borderBottom: !expanded && 0,
            },
            '&::before': {
              display: 'none',
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ alignItems: 'center', justifyContent: 'center' }}

          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemIcon>
                  <IconPicker icon="folderOpen" />
                </ListItemIcon>
                <ListItemText primary="Organisation details" secondary="Add and manage your organisation contact details and documents." />
              </ListItem>
            </List>
          </AccordionSummary>
          <AccordionDetails sx={{ borderBottom: expanded && expanded !== `panel-${0}` && '1px solid rgba(0, 0, 0, 0.12)' }}>
            <OrganisationSettingsForm></OrganisationSettingsForm>
          </AccordionDetails>
        </Accordion>
        <Accordion
          key={10}
          expanded={expanded === `panel-${10}`}
          onChange={handleChange(`panel-${10}`)}
          elevation={0}
          sx={{
            border: `1px solid rgba(0, 0, 0, 0.12)`, '&:not(:last-child)': {
              borderBottom: !expanded && 0,
            },
            '&::before': {
              display: 'none',
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ alignItems: 'center', justifyContent: 'center' }}

          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemIcon>
                  <IconPicker icon="logo" />
                </ListItemIcon>
                <ListItemText primary="Organisation Logo" secondary="Upload the logo for your organisation." />
              </ListItem>
            </List>
          </AccordionSummary>
          <AccordionDetails sx={{ borderBottom: expanded && expanded !== `panel-${1}` && '1px solid rgba(0, 0, 0, 0.12)' }}>

            {fileData.length === 0 && <img
              src={props.brandingConfig.logoUrl}
              alt="logo"
              //width="122"
              height="100"
              style={{ verticalAlign: 'middle', paddingLeft: '45px', paddingBottom: '15px' }}
            />}


            <Grid item xs={12} md={9} lg={9}>
              <ImageFileUpload fileData={fileData} setFileData={setFileData} imageCountLimit={1}></ImageFileUpload>
            </Grid>

            {fileData.length > 0 && <Button
              variant={props.isEditMode ? 'contained' : 'outlined'}
              disableElevation
              className="removeUppercase"
              startIcon={<SaveOutlinedIcon />}
              onClick={() => saveCompanyLogo()}
              sx={{ minWidth: '90px' }}
            >
              Save
            </Button>}

          </AccordionDetails>
        </Accordion>
        <Accordion
          key={1}
          expanded={expanded === `panel-${1}`}
          onChange={handleChange(`panel-${1}`)}
          elevation={0}
          sx={{
            border: `1px solid rgba(0, 0, 0, 0.12)`, '&:not(:last-child)': {
              borderBottom: !expanded && 0,
            },
            '&::before': {
              display: 'none',
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ alignItems: 'center', justifyContent: 'center' }}

          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemIcon>
                  <IconPicker icon="palette" />
                </ListItemIcon>
                <ListItemText primary="Branding" secondary="Configure the logo and colour scheme that will be used throughout Gaia." />
              </ListItem>
            </List>
          </AccordionSummary>
          <AccordionDetails sx={{ borderBottom: expanded && expanded !== `panel-${1}` && '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Stack direction='row' sx={{ width: '100%', justifyContent: 'flex-end', mb: 1, pl: '56px', pr: '56px' }}>
              <Button
                variant={props.isEditMode ? 'contained' : 'outlined'}
                disableElevation
                className="removeUppercase"
                startIcon={props.isEditMode ? <SaveOutlinedIcon /> : <EditIcon />}
                onClick={() => props.isEditMode ? props.updateStyles() : props.setIsEditMode(true)}
                sx={{ minWidth: '90px' }}
              >
                {props.isEditMode ? 'Save' : 'Edit'}
              </Button>
            </Stack>

            <Grid container spacing={4} sx={{ pl: '56px', pr: '56px', pt: '10px', mb: 4 }}>
              {/* <Grid item xs={12} md={3} lg={3}>
                <Typography variant="subtitle1" color="text.primary">
                  Logo URL:
                </Typography>
              </Grid> */}

              <Grid item xs={12} md={3} lg={3}>
                <Typography variant="subtitle1" color="text.primary">
                  Brand Colour:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <TextField
                  id="color-input"
                  //label="Save as"
                  variant="outlined"
                  size="small"
                  onChange={e => props.setBrandingConfigData('color', e.target.value)}
                  sx={{ backgroundColor: props.brandingConfig.color, borderRadius: '5px', borderColor: props.brandingConfig.color, width: '100%' }}
                  value={props.brandingConfig.color}
                  onClick={() => props.setPrimaryPickerActive(true)}
                  inputProps={{ className: "colourPickerTextField" }}
                  disabled={props.isEditMode ? false : true}
                />
                <HexColorPicker
                  color={props.brandingConfig.color}
                  onChange={e => props.setBrandingConfigData('color', e)}
                  style={{
                    position: 'absolute',
                    display: props.primaryPickerActive ? 'flex' : 'none',
                    zIndex: 1
                  }}
                  className="colourPickerTextField"
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Typography variant="subtitle1" color="text.primary">
                  Secondary Colour:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <TextField
                  id="color-input"
                  //label="Save as"
                  variant="outlined"
                  size="small"
                  onChange={e => props.setBrandingConfigData('secondaryColor', e.target.value)}
                  sx={{ backgroundColor: props.brandingConfig.secondaryColor, borderRadius: '5px', borderColor: props.brandingConfig.secondaryColor, width: '100%' }}
                  value={props.brandingConfig.secondaryColor}
                  onClick={() => props.setSecondaryPickerActive(true)}
                  inputProps={{ className: "colourPickerTextField" }}
                  disabled={props.isEditMode ? false : true}
                />
                <HexColorPicker
                  color={props.brandingConfig.secondaryColor}
                  onChange={e => props.setBrandingConfigData('secondaryColor', e)}
                  style={{
                    position: 'absolute',
                    display: props.secondaryPickerActive ? 'flex' : 'none',
                    zIndex: 1
                  }}
                  className="colourPickerTextField"
                />
              </Grid>
              {props.isEditMode && (
                <Grid item xs={12} md={12} lg={12}>
                  <MuiLink variant="subtitle2" onClick={() => props.resetDefaultColors()} sx={{ cursor: 'pointer' }}>
                    Reset to default colours
                  </MuiLink>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          key={2}
          expanded={expanded === `panel-${2}`}
          onChange={handleChange(`panel-${2}`)}
          elevation={0}
          sx={{
            border: `1px solid rgba(0, 0, 0, 0.12)`, '&:not(:last-child)': {
              borderBottom: !expanded && 0,
            },
            '&::before': {
              display: 'none',
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ alignItems: 'center', justifyContent: 'center' }}

          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemIcon>
                  <IconPicker icon="home" />
                </ListItemIcon>
                <ListItemText primary="Home Page Design" secondary="Configure the appearance of the hub homepage." />
              </ListItem>
            </List>
          </AccordionSummary>
          <AccordionDetails sx={{ borderBottom: expanded && expanded !== `panel-${2}` && '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Stack direction='row' sx={{ width: '100%', justifyContent: 'flex-end', mb: 1, pl: '56px', pr: '56px' }}>
              <Button
                variant={props.isEditMode ? 'contained' : 'outlined'}
                disableElevation
                className="removeUppercase"
                startIcon={props.isEditMode ? <SaveOutlinedIcon /> : <EditIcon />}
                onClick={() => props.isEditMode ? props.updateStyles() : props.setIsEditMode(true)}
                sx={{ minWidth: '90px' }}
              >
                {props.isEditMode ? 'Save' : 'Edit'}
              </Button>
            </Stack>

            <Grid container spacing={2} sx={{ pl: '56px', pr: '56px', pt: '10px', mb: 4 }}>
              <Grid item xs={12} md={3} lg={4} sx={{ mt: 1 }}>
                <Typography variant="subtitle1" color="text.primary">
                  Title:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={8} sx={{ mt: 1 }}>
                <TextField
                  id="title"
                  disabled={!props.isEditMode}
                  variant="outlined"
                  size="small"
                  onChange={e => props.setBrandingConfigData('titleCopy', e.target.value)}
                  value={props.brandingConfig.titleCopy || ''}
                  //sx={{ width: '55%' }}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={4} sx={{ mt: 1 }}>
                <Typography variant="subtitle1" color="text.primary">
                  Subtitle:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={8} sx={{ mt: 1 }}>
                <TextField
                  id="subtitle"
                  variant="outlined"
                  disabled={!props.isEditMode}
                  size="small"
                  onChange={e => props.setBrandingConfigData('bodyCopy', e.target.value)}
                  value={props.brandingConfig.bodyCopy || ''}
                  //sx={{ width: '55%' }}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={4} sx={{ mt: 1 }}>
                <Typography variant="subtitle1" color="text.primary">
                  Background image URL:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={8} sx={{ mt: 1 }}>
                <TextField
                  id="background-image-url"
                  variant="outlined"
                  size="small"
                  disabled={!props.isEditMode}
                  onChange={e => props.setBrandingConfigData('bannerImageUrl', e.target.value)}
                  value={props.brandingConfig.bannerImageUrl || ''}
                  sx={{ width: '100%' }}
                />
              </Grid>

              <Grid item xs={12} md={3} lg={4} sx={{ mt: 1 }}>
                <Typography variant="subtitle1" color="text.primary">
                  Desktop button columns:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={8} sx={{ mt: 1 }}>
                <Select
                  id="column-select"
                  variant="outlined"
                  size="small"
                  onChange={e => props.setBrandingConfigData('desktopButtonColumnCount', e.target.value)}
                  disabled={!props.isEditMode}
                  value={props.brandingConfig.desktopButtonColumnCount || 3}
                  sx={{ width: '100%' }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} md={3} lg={4} sx={{ mt: 1 }}>
                <Typography variant="subtitle1" color="text.primary">
                  Hide tools menu:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={8} sx={{ mt: 1 }}>
                <Checkbox
                  variant="outlined"
                  size="small"
                  disabled={!props.isEditMode}
                  onChange={e => props.setBrandingConfigData('hideToolsMenu', e.target.checked)}
                  checked={props.brandingConfig.hideToolsMenu || false}
                  sx={{ justifySelf: 'flex-start', p: 0 }} />
              </Grid>

              <Grid item xs={12} md={3} lg={4} sx={{ mt: 1 }}>
                <Typography variant="subtitle1" color="text.primary">
                  Enable manual user approval:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={8} sx={{ mt: 1 }}>
                <Checkbox
                  variant="outlined"
                  size="small"
                  disabled={!props.isEditMode}
                  onChange={e => props.setBrandingConfigData('enableUserApproval', e.target.checked)}
                  checked={props.brandingConfig.enableUserApproval || false}
                  sx={{ justifySelf: 'flex-start', p: 0 }} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          key={3}
          expanded={expanded === `panel-${3}`}
          onChange={handleChange(`panel-${3}`)}
          elevation={0}
          sx={{
            border: `1px solid rgba(0, 0, 0, 0.12)`, '&:not(:last-child)': {
              borderBottom: !expanded && 0,
            },
            '&::before': {
              display: 'none',
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ alignItems: 'center', justifyContent: 'center' }}

          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemIcon>
                  <IconPicker icon="button" />
                </ListItemIcon>
                <ListItemText primary="Buttons and Links" secondary="Configure the buttons and links that appear in the hub." />
              </ListItem>
            </List>
          </AccordionSummary>
          <AccordionDetails sx={{ borderBottom: expanded && expanded !== `panel-${3}` && '1px solid rgba(0, 0, 0, 0.12)' }}>
            <BrandingButtonForm />
          </AccordionDetails>
        </Accordion>

        {/* Footer section */}
        <Accordion
          key={4}
          expanded={expanded === `panel-${4}`}
          onChange={handleChange(`panel-${4}`)}
          elevation={0}
          sx={{
            border: `1px solid rgba(0, 0, 0, 0.12)`, '&:not(:last-child)': {
              borderBottom: !expanded && 0,
            },
            '&::before': {
              display: 'none',
            }
          }}
        >

          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemIcon>
                  <IconPicker icon="horizontalLine" />
                </ListItemIcon>
                <ListItemText primary="Footer HTML" secondary="Add HTML to control how your site footer displays." />
              </ListItem>
            </List>
          </AccordionSummary>
          <AccordionDetails sx={{ borderBottom: expanded && expanded !== `panel-${4}` && '1px solid rgba(0, 0, 0, 0.12)' }}>
            <FooterSettingsForm></FooterSettingsForm>
          </AccordionDetails>
        </Accordion>


      </Grid >
    </>
  );
};

export default BrandingForm;