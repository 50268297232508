import httpClient from '../../shared/http/http-client';

export default class HubAdminService {
  http;

  constructor() {
    this.http = httpClient;
  }

  /**
   * Toggle a tile active/inactive for a given hub.
   * @param {*} tileId - The ID of the tile to toggle on and off.
   * @returns - A success or error message.
   */
  async toggleTileActive(tileId) {
    return this.http.post('admin/hub/toggle-active-tile', { id: tileId })
      .then(() => {
        return;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  /**
   * Update the style for a single module.
   * @param {*} moduleStyleId - The ID of the record to update.
   * @param {*} updatedStyles - The styles to update. [body_copy, title_copy, banner_image_url].
   * @returns - A success or error message.
   */
  async updateHubModuleStyle(moduleStyleId, updatedStyles) {
    return this.http.post(`admin/hub/update-hub-module-style/${moduleStyleId}`, updatedStyles)
      .then((response) => {
        return;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }


  async updateHubBranding(branding) {
    return this.http.post('admin/hub/update-style', branding)
      .then(() => {
        return;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  async updateCompanyLogo(form) {
    return this.http.post('admin/hub/update-logo', form)
      .then(() => {
        return;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  async updateFooter(footerHtml) {
    return this.http.post('admin/hub/update-footer', footerHtml)
      .then(() => {
        return;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  async getAllProjectTypes() {
    return this.http.get(`admin/hub/project-type`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }


  async enableProjectType(id, details) {
    return this.http.post(`admin/hub/project-type/${id}/enable`, details)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async disableProjectType(id) {
    return this.http.post(`admin/hub/project-type/${id}/disable`, {})
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async toggleComingSoon(id, details) {
    return this.http.post(`admin/hub/project-type/${id}/toggle-coming-soon`, details)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }
}
