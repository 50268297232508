import httpClient from '../http/http-client';

export default class LocalPlanningAuthorityService {
  http;
  lpas;

  static instance = new LocalPlanningAuthorityService();

  static getInstance() {
    if (LocalPlanningAuthorityService.instance === null) LocalPlanningAuthorityService.instance = new LocalPlanningAuthorityService();
    return this.instance;
  }

  constructor() {
    this.http = httpClient;
  }

  async getLpas() {
    if (this.lpas) {
      return Promise.resolve(this.lpas)
    } else {
      return this.http.get(`data/lpa`)
        .then(response => {
          this.lpas = response.data;
          return response.data;
        });
    }
    
  }
}