import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Card, CardActionArea, CardContent, Grid, Paper, Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import CreateHubForm from '../../components/config/create-hub/create-hub-form';
import AdminProductAddOnService from '../../services/admin-product-add-on.service';
import { Link } from 'react-router-dom';
import { TrendingFlat } from '@mui/icons-material';

const AdminProductAddOn = () => {

  const adminProductAddOnService = new AdminProductAddOnService();

  const [addOns, setAddOns] = useState();

  useEffect(() => {
    adminProductAddOnService.getAllAddOns()
      .then(response => {
        setAddOns(response);
      });
  }, []);


  return (
    <>
      <Toolbar />
      <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={2}>
          {addOns?.map((addOn) => {
            return (
              <Grid item xs={12} md={4} lg={3} key={addOn.id}>
                <Card variant="outlined">
                  <CardActionArea
                    component={Link}
                    to={`/super-admin/product-add-on/${addOn.code}`}
                    sx={{
                      height: '100%',
                      minHeight: '350px',
                      p: 2
                    }}
                  >
                    <CardContent>
                      <Stack>
                        <Typography variant="h5" color="text.primary" sx={{ fontWeight: 300, marginBottom: '16px' }}>
                          {addOn.name}
                        </Typography>
                        <Typography variant="p" color="text.primary" sx={{ fontWeight: 200, marginBottom: '16px' }}>
                          {addOn.description}
                        </Typography>
                        <TrendingFlat color="primary" sx={{ fontSize: '28px' }} />
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          }
          )}
        </Grid>
      </Container>
    </>
  );
};

export default AdminProductAddOn;