import React, { useContext, useEffect, useState } from "react";
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';

import CssBaseline from '@mui/material/CssBaseline';
import gaiaTheme from '../../../theme';
import { HubDetailsContext } from '../../context/hub-details';
import NavBar from '../navigation/app/nav-bar';
import LeftNav from '../navigation/app/left-nav';
import RightNav from '../navigation/app/right-nav';
import { useLocation } from 'react-router-dom';
import { UserDetailsContext } from '../../context/user-details';
import WebsiteFooter from '../website-footer/website-footer';
import { InnerLeftNav } from '../navigation/app/inner-left-nav';

const AppPageLayout = ({ children }) => {

  const [newTheme, setNewTheme] = useState(gaiaTheme);
  const [rightNavOpen, setRightNavOpen] = useState(false);
  const hub = useContext(HubDetailsContext);
  const userDetailsContext = useContext(UserDetailsContext);

  const [hideLeftnav, setHideLeftNav] = useState(false);
  const [leftNavExpanded, setLeftNavExpanded] = useState(false);
  const [isMainHub, setIsMainHub] = useState(false);
  const location = useLocation();
  const [showInnerLeftNav, setShowInnerLeftNav] = useState(false);

  useEffect(() => {
    setNewTheme(hub.hubDetails.theme);
    setIsMainHub(hub.hubDetails.main_hub);
  }, [hub]);

  useEffect(() => {
    // setHideLeftNav(window.location.pathname.indexOf('user-sign-up-questions') !== -1)
    setHideLeftNav(true);
    setShowInnerLeftNav(location.pathname.startsWith('/settings'));

  }, [location.pathname]);

  useEffect(() => {
    if (userDetailsContext.userDetails) {
      // setHideLeftNav((userDetailsContext.userDetails.roles.includes('ECOLOGIST') && !userDetailsContext.userDetails.roles.includes('SELLER')))
      // setHideLeftNav(!isMainHub && userDetailsContext.userDetails.roles.includes('SELLER'))

      setHideLeftNav(true);
    }
  }, [userDetailsContext, isMainHub]);

  return (
    <ThemeProvider theme={{ ...newTheme }}>
      <CssBaseline />
      <NavBar setRightNavOpen={setRightNavOpen} leftNavHidden={hideLeftnav} leftNavExpanded={leftNavExpanded} />
      <Box sx={{ display: 'flex' }}>
        {!hideLeftnav && <LeftNav setLeftNavExpanded={setLeftNavExpanded} />}
        {/* {showInnerLeftNav && <Box sx={{ mr: 30 }}>
          <InnerLeftNav />
        </Box>} */}
        <Box
          component="main"
          sx={{
            maxWidth: '100vw',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'auto',
          }}
        >

          <Box sx={{ maxWidth: '100%', minHeight: '100vh', }}>{children}</Box>
          <WebsiteFooter></WebsiteFooter>
        </Box>
        <RightNav open={rightNavOpen} setRightNavOpen={setRightNavOpen} />
      </Box>
    </ThemeProvider>
  );
};

export default AppPageLayout;